import { useEffect, useRef } from "react";

/**
 * Custom hook that returns previous value of a stateful value (e.g. from useState)
 * @param value
 * @returns previous value of that stateful value
 */
export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
