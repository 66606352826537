import * as React from "react";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { API_DATE_FORMAT } from "../hooks/useLoadAllData";

export default function Main() {
    const exchanges = useSelector((state) => state.exchanges.managedExchanges);
    const indexes = useSelector((state) => state.indexes.managedIndexes);
    const nonDisabledIndexes = indexes.filter((x) => !x.disabled_from);
    const securities = useSelector(
        (state) => state.securities.managedSecurities,
    );
    const today = DateTime.local().toFormat(API_DATE_FORMAT);
    const tomorrow = DateTime.local()
        .plus({ days: 1 })
        .toFormat(API_DATE_FORMAT);
    const yesterday = DateTime.local()
        .minus({ days: 1 })
        .toFormat(API_DATE_FORMAT);

    return (
        <div className="p-10 text-xl">
            <div>
                <span className="font-bold">Active Indexes</span>:{" "}
                {nonDisabledIndexes.length}
            </div>
            <div>
                <span className="font-bold">Managed Securities</span>:{" "}
                {securities.length}
            </div>
            <div>
                <span className="font-bold">
                    Exchanges Closed Yesterday ({yesterday})
                </span>
                : {exchanges.filter((x) => !x.trade_dates[yesterday]).length}
            </div>
            <div>
                <span className="font-bold">
                    Exchanges Closed Today ({today})
                </span>
                : {exchanges.filter((x) => !x.trade_dates[today]).length}
            </div>
            <div>
                <span className="font-bold">
                    Exchanges Closed Tomorrow ({tomorrow})
                </span>
                : {exchanges.filter((x) => !x.trade_dates[tomorrow]).length}
            </div>
        </div>
    );
}
