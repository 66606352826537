import * as React from "react";
import { useSelector } from "react-redux";
import DataTable, {
    multiSelectColumn,
    nonFilterableColumn,
    convertToPercent,
    dateFilterColumn,
    columnWidth,
} from "../../components/DataTable";

export default function CentralBanks() {
    const centralBanks = useSelector((state) => state.securities.centralBanks);

    const TABLE_OPTIONS = {
        sortOrder: {
            name: "instrument_id",
            direction: "asc",
        },
    };

    const COLUMNS = [
        multiSelectColumn("name", "Name"),
        multiSelectColumn("instrument_id", "Instrument ID"),
        nonFilterableColumn(
            "interest_rate",
            "Interest Rate",
            convertToPercent(3),
        ),
        multiSelectColumn("currency", "Currency"),
        dateFilterColumn("start_date", "Start Date", columnWidth("10%")),
        dateFilterColumn("end_date", "End Date", columnWidth("10%")),
    ];

    return (
        <>
            <DataTable
                title={"Central Banks Interest Rates"}
                data={centralBanks}
                columns={COLUMNS}
                options={TABLE_OPTIONS}
            />
        </>
    );
}
