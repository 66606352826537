import * as React from "react";
import { useSelector } from "react-redux";
import DataTable, {
    booleanValueRenderer,
    columnWidth,
    customValueRenderer,
    dateFilterColumn,
    dropDownColumn,
    freeTextColumn,
    multiSelectColumn,
    nonFilterableColumn,
} from "../../components/DataTable";
import { History, Add } from "@mui/icons-material";
import { useEffect, useState } from "react";
import RecalculateIndexes from "./RecalculateIndexes";
import {
    BooleanField,
    CurrencyField,
    DateField,
    SelectField,
    TextField,
} from "../../components/Form";
import {
    AddRecordDialog,
    EditRecordDialog,
} from "../../components/RecordDialog";
import { getCountryName } from "../../utils/countries";

export const INDEX_TYPE = {
    net_total_return: "Net Total Return",
    gross_total_return: "Gross Total Return",
};

export const SECURITIES_TYPE = {
    equity: "Equity",
    bonds: "Bonds",
    combo: "Combo",
};

export const TRIGGER_TYPE = {
    normal: "Normal",
    tase_early_calculation: "TASE Early Calculation",
    late_calculation: "Late Calculation",
};

export const INDEX_FAMILY = {
    index_israeli_bonds: "iNDEX Israeli Bonds",
    index_global_equity: "iNDEX Global Equity",
    index_israeli_equity: "iNDEX Israeli Equity",
    index_multi_asset: "iNDEX Multi-Asset",
    index_global_bonds: "iNDEX Global Bonds",
};

export const INDEX_RECONSTITUTION_FREQUENCY = {
    monthly: "חודשית",
    semi_annual: "חצי-שנתית",
    quarterly: "רבעונית",
    yearly: "שנתית",
};

export const INDEX_REBALANCE_PERIODICITY = {
    monthly: "חודשית",
    quarterly: "רבעונית",
    yearly: "שנתית",
};

export const TRADING_DAYS = {
    sun_to_thu: "Sun to Thu",
    sun_to_fri: "Sun to Fri",
    mon_to_fri: "Mon to Fri",
};

export const INDEX_PRICING_FREQUENCY = {
    listed: "רציף",
    end_of_day: "סוף יום",
};

export const INDEX_WEIGHTING_TYPE = {
    market_cap: "Market Cap",
    equal_weight: "Equal Weight",
    esg_weight: "ESG Weight",
};

export const INDEX_CPI_ILS_LINKAGE = {
    balanced: "Balanced",
};

const COLUMNS = [
    freeTextColumn("name", "Name", columnWidth("30%")),
    multiSelectColumn("internal_id", "ID"),
    dropDownColumn(
        "type",
        "Type",
        customValueRenderer((v) => INDEX_TYPE[v]),
    ),
    multiSelectColumn("currency", "Currency"),
    dropDownColumn(
        "securities_type",
        "Securities Type",
        customValueRenderer((v) => SECURITIES_TYPE[v]),
    ),
    dropDownColumn("has_product", "Has Product", booleanValueRenderer()),
    freeTextColumn("isin", "ISIN", columnWidth("30%")),
    freeTextColumn(
        "trigger_type",
        "Trigger Type",
        customValueRenderer((v) => TRIGGER_TYPE[v]),
    ),
    freeTextColumn("bloomberg_ticker", "Bloomberg Ticker", columnWidth("30%")),
    freeTextColumn("ric", "RIC", columnWidth("30%")),
    dateFilterColumn("disabled_from", "Disabled From"),

    freeTextColumn("index_short_name_hebrew", "Index Short Name Hebrew"),
    freeTextColumn("index_pdf_name_hebrew", "Index PDF Name Hebrew"),
    freeTextColumn("index_name_english", "Index Name English"),
    freeTextColumn("index_short_name_english", "Index Short Name English"),
    freeTextColumn("index_description_hebrew", "Index Description Hebrew"),
    freeTextColumn("index_description_english", "Index Description English"),
    freeTextColumn("parent_index", "Parent Index"),
    freeTextColumn(
        "index_family",
        "Index Family",
        customValueRenderer((v) => INDEX_FAMILY[v]),
    ),
    dateFilterColumn("index_launch_date", "Index Launch Date"),
    dateFilterColumn("index_base_date", "Index Base Date"),
    nonFilterableColumn("index_base_value", "Index Base Value"),
    freeTextColumn(
        "index_reconstitution_frequency",
        "Index Reconstitution Frequency",
        customValueRenderer((v) => INDEX_RECONSTITUTION_FREQUENCY[v]),
    ),
    freeTextColumn(
        "index_rebalance_periodicity",
        "Index Rebalance Periodicity",
        customValueRenderer((v) => INDEX_REBALANCE_PERIODICITY[v]),
    ),
    freeTextColumn(
        "index_pricing_frequency",
        "Index Pricing Frequency",
        customValueRenderer((v) => INDEX_PRICING_FREQUENCY[v]),
    ),
    freeTextColumn(
        "trading_days",
        "Trading Days",
        customValueRenderer((v) => TRADING_DAYS[v]),
    ),
    freeTextColumn("is_global", "Is Global"),
    freeTextColumn(
        "country_code",
        "Country Code",
        customValueRenderer(getCountryName),
    ),
    freeTextColumn("geographical_region", "Geographical Region"),
    freeTextColumn(
        "index_weighting_type",
        "Index Weighting Type",
        customValueRenderer((v) => INDEX_WEIGHTING_TYPE[v]),
    ),
    nonFilterableColumn("constituent_weight_cap", "Constituent Weight Cap"),
    nonFilterableColumn("constituent_weight_max", "Constituent Weight Max"),
    nonFilterableColumn("constituent_weight_min", "Constituent Weight Min"),
    nonFilterableColumn("corp_issuer_weight_cap", "Corp Issuer Weight Cap"),
    nonFilterableColumn("sector_weight_cap", "Sector Weight Cap"),
    freeTextColumn(
        "cpi_ils_linkage",
        "CPI ILS Linkage",
        customValueRenderer((v) => INDEX_CPI_ILS_LINKAGE[v]),
    ),
    freeTextColumn(
        "website_category_publication",
        "Website Category Publication",
    ),
    freeTextColumn("website_category_hebrew", "Website Category Hebrew"),
    freeTextColumn("website_category_english", "Website Category English"),
    freeTextColumn("website_page_link_hebrew", "Website Page Link Hebrew"),
    freeTextColumn("website_page_link_english", "Website Page Link English"),
    freeTextColumn("methodology_link", "Methodology Link"),
    freeTextColumn(
        "bloomberg_levels_publication",
        "Bloomberg Levels Publication",
    ),
    freeTextColumn("bloomberg_wgt_publication", "Bloomberg WGT Publication"),
    freeTextColumn(
        "refinitiv_levels_publication",
        "Refinitiv Levels Publication",
    ),
    freeTextColumn("refinitiv_wgt_publication", "Refinitiv WGT Publication"),
    freeTextColumn("markit_publication", "Markit Publication"),
    freeTextColumn("markit_symbol", "Markit Symbol"),
    freeTextColumn("bizterminal_publication", "BizTerminal Publication"),
    freeTextColumn("bizterminal_symbol", "BizTerminal Symbol"),
    freeTextColumn("megama_publication", "Megama Publication"),
    freeTextColumn("megama_symbol", "Megama Symbol"),
    freeTextColumn("globestradeone_publication", "GlobesTradeOne Publication"),
    freeTextColumn("globestradeone_symbol", "GlobesTradeOne Symbol"),
];

const EDIT_FIELDS = [
    {
        name: "name",
        label: "Name",
        type: TextField,
        required: true,
    },
    {
        name: "type",
        label: "Type",
        type: SelectField,
        options: Object.keys(INDEX_TYPE).map((k) => ({
            id: k,
            label: INDEX_TYPE[k],
        })),
        required: true,
    },
    {
        name: "currency",
        label: "Currency",
        type: CurrencyField,
        required: true,
    },
    {
        name: "securities_type",
        label: "Securities Type",
        type: SelectField,
        options: Object.keys(SECURITIES_TYPE).map((k) => ({
            id: k,
            label: SECURITIES_TYPE[k],
        })),
        required: true,
    },
    {
        name: "has_product",
        label: "Has Product",
        type: BooleanField,
        required: true,
        defaultValue: false,
    },
    {
        name: "isin",
        label: "ISIN",
        type: TextField,
        required: true,
    },
    {
        name: "bloomberg_ticker",
        label: "Bloomberg Ticker",
        type: TextField,
        required: false,
    },
    {
        name: "ric",
        label: "RIC",
        type: TextField,
        required: false,
    },
    {
        name: "trigger_type",
        label: "Trigger Type",
        type: SelectField,
        options: Object.keys(TRIGGER_TYPE).map((k) => ({
            id: k,
            label: TRIGGER_TYPE[k],
        })),
        required: true,
    },
    {
        name: "disabled_from",
        label: "Disabled From",
        type: DateField,
        required: false,
        // Allow user to re-enable index by sending null value
        allowEmpty: true,
    },
];

const ADD_FIELDS = [
    ...EDIT_FIELDS,
    {
        name: "internal_id",
        label: "Index ID",
        type: TextField,
        required: true,
    },
];

export default function ManagedIndexes() {
    const [showRecalculateDialog, setShowRecalculateDialog] = useState(false);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [editRowIndex, setEditRowIndex] = useState(0);
    const [disableRecalc, setDisableRecalc] = useState(false);
    const [indexes, setIndexes] = useState(
        useSelector((state) => state.indexes.managedIndexes),
    );
    const currentProcesses = useSelector(
        (state) => state.indexes.currentProcesses,
    );
    const indexCalcProcess = currentProcesses.find(
        (x) => x.name === "index_calculation",
    );

    const TABLE_OPTIONS = {
        sortOrder: {
            name: "internal_id",
            direction: "asc",
        },
        customIcons: [
            {
                title: "Recalculate All Indexes",
                icon: <History />,
                onClick: () => {
                    setShowRecalculateDialog(true);
                },
                disabled: disableRecalc,
            },
            {
                title: "Add New Index",
                icon: <Add />,
                onClick: () => {
                    setShowAddDialog(true);
                },
                disabled: disableRecalc,
            },
        ],
        onEditRow: (index) => {
            setEditRowIndex(index);
            setShowEditDialog(true);
        },
    };

    useEffect(() => {
        setDisableRecalc(
            indexCalcProcess && indexCalcProcess.status === "started",
        );
    }, [indexCalcProcess]);

    const onFinishedEditingRow = async (updatedRecord) => {
        // Refresh results to show latest updated record
        const newIndexes = [...indexes];
        newIndexes[editRowIndex] = updatedRecord;
        setIndexes(newIndexes);
        setShowEditDialog(false);
    };

    const onFinishedAddingRow = async (newRecord) => {
        // Refresh results to show new record
        const newIndexes = [newRecord, ...indexes];
        setIndexes(newIndexes);
        setShowAddDialog(false);
    };

    return (
        <>
            <RecalculateIndexes
                show={showRecalculateDialog}
                onCancelled={() => setShowRecalculateDialog(false)}
                onFinished={() => setShowRecalculateDialog(false)}
            />
            <AddRecordDialog
                title="Add Index"
                show={showAddDialog}
                fields={ADD_FIELDS}
                createAPIPath={"managed_index"}
                onCancelled={() => setShowAddDialog(false)}
                onFinished={onFinishedAddingRow}
            />
            <EditRecordDialog
                title="Edit Index"
                show={showEditDialog}
                fields={EDIT_FIELDS}
                record={indexes[editRowIndex]}
                updateAPIPath={"managed_index"}
                onCancelled={() => setShowEditDialog(false)}
                onFinished={onFinishedEditingRow}
            />
            <DataTable
                title={"Managed Indexes"}
                data={indexes}
                columns={COLUMNS}
                options={TABLE_OPTIONS}
            />
        </>
    );
}
