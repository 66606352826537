import * as React from "react";
import currencyCodes from "currency-codes";
import { Form as FForm, Field as FField } from "react-final-form";
import {
    Autocomplete,
    Button,
    Checkbox,
    createFilterOptions,
    FormControlLabel,
    TextField as MUITextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FORM_ERROR } from "final-form";
import ErrorLabel from "./Error";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { DISPLAY_DATE_FORMAT } from "./DataTable";
import { API_DATE_FORMAT } from "../hooks/useLoadAllData";
import { useSelector } from "react-redux";
import { getSecurityName } from "../store/securities";
import { getExchangeName } from "../store/exchanges";
import { DateTime } from "luxon";
import FileUploader from "./FileUploader";
import { useCallback, useMemo } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { getAllCountries } from "../utils/countries";
import { twMerge } from "tailwind-merge";

/**
 * All different types of input fields
 */

function TextInput({ input, meta, field, displayError, ...rest }) {
    return (
        <MUITextField
            {...input}
            label={field.label || field.name}
            value={input.value}
            onChange={(e) => input.onChange(e.target.value)}
            helperText={displayError}
            {...rest}
            error={displayError ? true : false}
        />
    );
}

function CheckboxInput({ input, meta, field, displayError, ...rest }) {
    return (
        <div
            {...rest}
            className={twMerge(
                "flex flex-row items-center",
                field.allowUnset && ![true, false].includes(input.value)
                    ? "text-gray-500 line-through"
                    : "",
            )}
        >
            <FormControlLabel
                {...input}
                control={
                    <Checkbox
                        checked={input.value}
                        onChange={(e) => {
                            if (
                                field.allowUnset &&
                                input.value === false &&
                                e.target.checked
                            ) {
                                input.onChange(null);
                            } else {
                                input.onChange(e.target.checked);
                            }
                        }}
                    />
                }
                label={field.label || field.name}
            />
        </div>
    );
}

function DateInput({ input, meta, field, displayError, ...rest }) {
    return (
        <MobileDatePicker
            {...input}
            label={field.label || field.name}
            value={input.value}
            onChange={(newValue) => {
                input.onChange(newValue);
            }}
            renderInput={(params) => (
                <TextInput
                    {...params}
                    field={field}
                    meta={meta}
                    input={input}
                    displayError={displayError}
                />
            )}
            ampm={false}
            inputFormat={DISPLAY_DATE_FORMAT}
            {...rest}
        />
    );
}

function FileUploaderInput({ input, meta, field, displayError, ...rest }) {
    return (
        <>
            <div>{field.label || field.name}</div>
            <FileUploader
                {...input}
                onFileChosen={(filename, fileBody) => {
                    input.onChange({ filename, fileBody });
                }}
                {...rest}
                displayError={displayError}
            />
        </>
    );
}

function SelectInput({ input, meta, field, displayError, multiple, ...rest }) {
    const optionsCb =
        field.options ||
        field.type.options ||
        field.optionsFromSelector ||
        field.type.optionsFromSelector;
    const optionsFromSelector = useSelector(
        optionsCb instanceof Function ? optionsCb : () => {},
    );
    // Callback function to generate options (e.g. used when generating options from managed securities)
    const options =
        optionsCb instanceof Function ? optionsFromSelector : optionsCb;

    // In case of default/initial value - we convert it from ID only, to an {id: x, label: y} object
    const convertValue = useCallback(
        (v) => {
            let value =
                v instanceof Object ? v : options.find((x) => x.id === v);

            if (value === undefined) {
                // In case user cleared the selection
                value = null;
            }

            if (!(v instanceof Object)) {
                // In case of initial value being set, and not changed by the user afterwards - make sure it's
                // the entire { id: x, label: y } object, and not just `id`.
                input.onChange(value);
            }

            return value;
        },
        [input, options],
    );

    const multiValue = useMemo(
        () =>
            input.value instanceof Array && input.value.length > 0
                ? input.value.map(convertValue)
                : [],
        [input.value],
    );
    let autocompleteValue;

    if (multiple) {
        // Multiple choice list - an array of values
        autocompleteValue = multiValue;
    } else {
        autocompleteValue = convertValue(input.value);
    }

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const filterOptions = createFilterOptions({
        limit: 40,
    });

    return (
        <Autocomplete
            {...input}
            multiple={multiple}
            filterOptions={filterOptions}
            options={options}
            renderInput={(params) => (
                <MUITextField
                    {...params}
                    label={field.label || field.name}
                    helperText={displayError}
                    error={displayError ? true : false}
                />
            )}
            renderOption={(props, option, { selected }) =>
                multiple ? (
                    <li {...props} key={option.id}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.label}
                    </li>
                ) : (
                    <li {...props} key={option.id}>
                        {option.label}
                    </li>
                )
            }
            value={autocompleteValue}
            onChange={(e, value) => input.onChange(value)}
            isOptionEqualToValue={(option, value) =>
                value === undefined || value === "" || option.id === value.id
            }
            {...rest}
        />
    );
}

/**
 * All different types of fields. Each field has the following properties:
 *      component: input component to be used for display
 *      validate: callback function that validates input (returns an Error instance with error message if not validated), and will
 *              return the parsed value (e.g. convert string to float).
 *      parse: callback function that parses initial default value and returns a value that will be fed into the input
 *          component (e.g. converting epoch Unix timestamp into a DateTime object to be used by DatePicker component).
 *          If this function is not provided, default value will be passed as-is to the component.
 */

// Base field
const BaseField = {
    component: TextInput,
    validate: (x) => x,
    parse: (x) => x,
};

export const FileField = {
    ...BaseField,
    component: FileUploaderInput,
};

export const TextField = {
    ...BaseField,
};

export const IntField = {
    ...BaseField,
    validate: (x) => {
        if (!/\d+/.test(x)) return new Error("Not a valid whole number");
        return parseInt(x);
    },
};

export const FloatField = {
    ...BaseField,
    validate: (x) => {
        if (!/^\d+\.?\d*$/.test(x)) return new Error("Not a valid number");
        return parseFloat(x);
    },
};

export const DecimalField = {
    ...BaseField,
    validate: (x) => {
        if (!/^\d+\.?\d*$/.test(x)) return new Error("Not a valid number");
        return x; // Decimal fields are saved as strings (to not lose accuracy)
    },
};

export const BooleanField = {
    ...BaseField,
    component: CheckboxInput,
};

export const DateField = {
    ...BaseField,
    component: DateInput,
    validate: (x) => x.toFormat(API_DATE_FORMAT),
    parse: (x) => (x ? DateTime.fromMillis(x) : null), // Convert from Unix epoch timestamp
};

export const SelectField = {
    ...BaseField,
    component: SelectInput,
    validate: (x) => x.id,
};

export const MultipleSelectField = {
    ...BaseField,
    component: (props) => <SelectInput multiple {...props} />,
    validate: (x) => x.map((v) => v.id),
};

export const CurrencyField = {
    ...SelectField,
    options: currencyCodes.codes().map((c) => ({ id: c, label: c })),
};

export const MultipleCurrenciesField = {
    ...CurrencyField,
    ...MultipleSelectField,
};

export const CountryField = {
    ...SelectField,
    options: getAllCountries().map((c) => ({ id: c.iso2, label: c.country })),
};

export const MultipleCountriesField = {
    ...CountryField,
    ...MultipleSelectField,
};

export const SecurityField = {
    ...SelectField,
    optionsFromSelector: (state) =>
        state.securities.managedSecurities.map((s) => ({
            id: s.internal_id,
            label: getSecurityName(s),
        })),
};

export const MultipleSecuritiesField = {
    ...SecurityField,
    ...MultipleSelectField,
};

export const ExchangeField = {
    ...SelectField,
    optionsFromSelector: (state) =>
        state.exchanges.managedExchanges.map((e) => ({
            id: e._id,
            label: getExchangeName(e),
        })),
};

export const MultipleExchangesField = {
    ...ExchangeField,
    ...MultipleSelectField,
};

export const IndexField = {
    ...SelectField,
    optionsFromSelector: (state) =>
        state.indexes.managedIndexes.map((index) => ({
            id: index._id,
            label: `${index.internal_id} - ${index.name}`,
        })),
};

export const MultipleIndexesField = {
    ...IndexField,
    ...MultipleSelectField,
};

export const SourceField = {
    ...SelectField,
    optionsFromSelector: (state) =>
        state.securities.managedSources.map((e) => ({
            id: e._id,
            label: e.name,
        })),
};

export const MultipleSourcesField = {
    ...SourceField,
    ...MultipleSelectField,
};

// In case field type is not explicitly mentioned
const DEFAULT_FIELD_TYPE = TextField;

/** Renders the form field - field must be one of IntField, TextField, etc. */
function Field({ field }) {
    const fieldType = field.type || DEFAULT_FIELD_TYPE;
    return (
        <FField
            field={field}
            name={field.name}
            className="w-[300px]"
            parse={(value) => (value === "" ? "" : value)}
            render={({ meta, ...rest }) => {
                // Determine if we should display an error for this field
                const displayError =
                    meta.invalid && !meta.modifiedSinceLastSubmit
                        ? meta.submitError
                        : null;

                // Render the field type's appropriate UI input component
                return fieldType.component({
                    displayError,
                    field,
                    meta,
                    ...rest,
                });
            }}
            placeholder={field.placeholder}
        />
    );
}

/** Our own custom form.
 *
 * @param fields Array of fields to display, each field is an object of:
 *      {
 *          "name": name as it will appear in values when submitted,
 *          "label": display label/title (UI),
 *          "defaultValue": default field value,
 *          "type": field type (e.g. `IntField`) - this will be used for the UI component to display, input validation,
 *                  conversion of input (e.g. text to float), etc.,
 *          "required": if true, mandatory field,
 *          "allowEmpty": if true, null values are allowed and will be saved
 *          "allowUnset": if true, will allow unsetting the value for this field, but unlike allowEmpty, this value
 *              will NOT be sent forward to the API at all, in case it's unset (currently implemented for BooleanField)
 *      }
 * @param onSubmit (async function) called when the form is submitted - at this stage, all values (passed as an object)
 *      are assumed to be validated correctly and converted to the appropriate types (e.g. string -> float).
 *      If onSubmit fails, it throws an exception, which will be rendered as a general form error. Otherwise,
 *      it assumes it was successful.
 * @param submitButtonText Text on the form submit button
 * @param resetButtonText Text on the form reset button
 * @param additionalButtons list of additional buttons to display, each item in the list is an object of:
 *          { "text": button text, onPress: called when button is pressed }
 * @param hideResetButton (optional, default=False) if True, reset button will be hidden
 */
export default function Form({
    fields,
    onSubmit,
    submitButtonText = "Submit",
    resetButtonText = "Reset",
    additionalButtons = [],
    hideResetButton = false,
    className = "",
    buttonsClassName = "",
}) {
    const initialValues = useMemo(
        () =>
            Object.fromEntries(
                fields.map((f) => [
                    f.name,
                    (f.type || DEFAULT_FIELD_TYPE).parse(f.defaultValue),
                ]),
            ),
        [],
    );

    /** Validate values, convert them if needed (e.g. string -> float) and call callback function */
    const onPreSubmit = async (values) => {
        // Validate values and convert to proper types (e.g. string -> float)
        let parsedValues = fields.map((field) => {
            const fieldType = field.type || DEFAULT_FIELD_TYPE;
            const fieldValue = values[field.name];
            const isEmptyValue =
                fieldValue === null ||
                fieldValue === "" ||
                fieldValue === undefined ||
                fieldValue.length === 0;

            let parsedFieldValue;

            if (!field.required && isEmptyValue) {
                // Allow empty values (null)
                if (field.type === TextField && fieldValue === "") {
                    // Is an optional empty text field (e.g. someone editing a CA note and clearing it)
                    parsedFieldValue = "";
                } else {
                    parsedFieldValue = null;
                }
            } else if (field.required && isEmptyValue) {
                // Empty field value, but it's a mandatory field
                parsedFieldValue = new Error("Mandatory field");
            } else {
                // Parse/convert the value
                parsedFieldValue = fieldType.validate(fieldValue);
            }

            return [field, parsedFieldValue];
        });
        const errorValues = parsedValues.filter(([_, v]) => v instanceof Error);

        if (errorValues.length > 0) {
            // Some validation errors were found - return those errors
            return Object.fromEntries(
                errorValues.map(([f, v]) => [f.name, v.message]),
            );
        }

        // Filter out any null values (can happen with optional fields)
        // Do allow for null values in case of fields with allowEmpty == true
        parsedValues = parsedValues.filter(
            ([f, v]) => v !== null || f.allowEmpty,
        );

        // Validation checked out - pass the converted/parsed values to the callback function
        try {
            await onSubmit(
                Object.fromEntries(parsedValues.map(([f, v]) => [f.name, v])),
            );
        } catch (exc) {
            // Return a general submission error
            console.error(exc);
            const message = exc.response?.data?.Message || exc.message;
            return { [FORM_ERROR]: message };
        }

        // If we've reached here, this means form submission was successful, nothing to do here
    };

    return (
        <FForm
            initialValues={initialValues}
            onSubmit={onPreSubmit}
            render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                submitError,
            }) => (
                <form
                    onSubmit={handleSubmit}
                    className={twMerge(
                        "flex flex-row flex-wrap gap-y-4 gap-x-4",
                        className,
                    )}
                >
                    {fields.map((field, index) => (
                        <div key={field.name} className="flex items-center">
                            <Field field={field} />
                        </div>
                    ))}
                    {submitError && (
                        <ErrorLabel>Error: {submitError}</ErrorLabel>
                    )}
                    <div
                        className={twMerge(
                            "flex flex-row items-center",
                            buttonsClassName,
                        )}
                    >
                        <LoadingButton
                            loading={submitting}
                            type="submit"
                            variant="contained"
                            sx={{
                                mr: 2,
                            }}
                        >
                            {submitButtonText}
                        </LoadingButton>
                        {!hideResetButton && (
                            <Button
                                variant="outlined"
                                onClick={form.reset}
                                disabled={submitting || pristine}
                                sx={{
                                    mr: 2,
                                }}
                            >
                                {resetButtonText}
                            </Button>
                        )}
                        {additionalButtons.map((button) => (
                            <Button
                                key={button.text}
                                variant="outlined"
                                onClick={button.onPress}
                                sx={{
                                    mr: 2,
                                }}
                            >
                                {button.text}
                            </Button>
                        ))}
                    </div>
                </form>
            )}
        />
    );
}
