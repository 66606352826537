import * as React from "react";
import Form, {
    BooleanField,
    FileField,
    SelectField,
} from "../../components/Form";
import QueryDataTable from "../../components/QueryDataTable";
import { FileUpload } from "@mui/icons-material";
import { Dialog, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import Authentication from "../../modules/authentication";
import { dateFilterColumn } from "../../components/DataTable";

const gApiClient = Authentication.getAPIClient();

export default function APIReports() {
    const [showUploadDialog, setShowUploadDialog] = useState(false);
    const QUERY_FIELDS = [
        {
            name: "report_type",
            label: "Report Type",
            type: SelectField,
            optionsFromSelector: (state) =>
                state.reports.reportTypes.map((s) => ({
                    id: s,
                    label: s,
                })),
            required: true,
        },
    ];

    const onSubmit = async (values) => {
        const isXLS =
            values.report_file.filename.endsWith(".xls") ||
            values.report_file.filename.endsWith(".xlsx");

        const response = await gApiClient.callApi(
            `upload_report/${values.report_type}?overwrite=${
                values.overwrite ? "1" : "0"
            }&file_format=${isXLS ? "xls" : "csv"}`,
            {
                method: "POST",
                body: values.report_file.fileBody,
            },
        );

        console.log("Response", response.data);

        setShowUploadDialog(false);
    };

    const COLUMNS = [
        dateFilterColumn("available_from", "Available From", {
            dateFormat: "dd/MM/yyyy HH:mm",
        }),
    ];

    const UPLOAD_REPORT_FIELDS = [
        {
            name: "report_type",
            label: "Report Type",
            type: SelectField,
            optionsFromSelector: (state) =>
                state.reports.reportTypes.map((s) => ({
                    id: s,
                    label: s,
                })),
            required: true,
        },
        {
            name: "report_file",
            label: "Report File",
            type: FileField,
            required: true,
        },
        {
            name: "overwrite",
            label: "Overwrite Previous Report?",
            type: BooleanField,
            required: true,
            defaultValue: true,
        },
    ];

    // Format the CSV filename
    const formatCSVFilename = (values) => {
        return `report-${values.report_type}.csv`;
    };

    return (
        <>
            <Dialog
                open={showUploadDialog}
                onClose={() => {
                    setShowUploadDialog(false);
                }}
                maxWidth={"md"}
                fullWidth={true}
            >
                <div className="p-5 flex flex-col items-center">
                    <div className="text-center mb-8 text-3xl">
                        Upload Report
                    </div>
                    <Form
                        fields={UPLOAD_REPORT_FIELDS}
                        onSubmit={onSubmit}
                        submitButtonText="Upload"
                        hideResetButton={true}
                        additionalButtons={[
                            {
                                text: "Cancel",
                                onPress: () => setShowUploadDialog(false),
                            },
                        ]}
                    />
                </div>
            </Dialog>
            <div className="absolute right-10 top-10">
                <Tooltip title={"Upload Report"}>
                    <IconButton onClick={() => setShowUploadDialog(true)}>
                        <FileUpload />
                    </IconButton>
                </Tooltip>
            </div>
            <QueryDataTable
                tableColumns={COLUMNS}
                showAllColumns={true}
                queryFields={QUERY_FIELDS}
                queryAPIPath="report"
                dynamicLoad={true}
                formatCSVFilename={formatCSVFilename}
            />
        </>
    );
}
