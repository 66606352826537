import { Route, Routes } from "react-router-dom";
import Authentication from "./modules/authentication";
import AppBar from "./components/AppBar";
import SideMenu from "./components/SideMenu";
import { routes } from "./routes";
import { useSelector } from "react-redux";
import { useLoadAllData } from "./hooks/useLoadAllData";
import Loading from "./components/Loading";
import { useCheckForLoginAndRedirect } from "./hooks/useCheckForLoginAndRedirect";
import { useEffect, useState } from "react";
import { getWebsiteStage, WEBSITE_URLS_BY_STAGE } from "./modules/stages";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

/** An interception switch - intercept each time URL is changed, and checks if user is logged-in -
 * if not, redirect to login screen. */
function InterceptSwitch({ routes }) {
    // This checks if location changed, and if user is not logged in and needs to be redirected to login page
    useCheckForLoginAndRedirect();

    return (
        <Routes>
            {routes.map((route) => (
                <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                />
            ))}
        </Routes>
    );
}

function App() {
    const loginState = useSelector((state) => state.user.loginState);
    const userDetails = useSelector((state) => state.user.userDetails);
    const userStage = userDetails ? userDetails.stage : null;
    const websiteStage = getWebsiteStage();
    const loadingData = useLoadAllData();
    const [showRedirectDialog, setShowRedirectDialog] = useState(false);
    const [redirecting, setRedirecting] = useState(false);

    useEffect(() => {
        if (!userStage) return;

        if (userStage !== websiteStage) {
            // User stage is not the same as current website stage - show a dialog letting the user know
            // that we're going to redirect him to the correct website URL.
            setShowRedirectDialog(true);
        }
    }, [userStage]);

    const redirectToStagingWebsite = async () => {
        setRedirecting(true);

        // Log-off from this website
        Authentication.logOut();

        setTimeout(() => {
            // Redirect user to correct staging website
            window.location.replace(
                `${window.location.protocol}//${WEBSITE_URLS_BY_STAGE[userStage][0]}`,
            );
        }, 500);
    };

    return (
        <div className="flex w-full flex-col">
            <Dialog
                open={showRedirectDialog}
                onClose={() => {
                    // Prevent from canceling the dialog by clicking on the backdrop
                    return;
                }}
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogTitle>Wrong Staging Website</DialogTitle>
                <DialogContent>
                    <div>
                        <b>User</b>: {userDetails && userDetails.email}
                    </div>
                    <div>
                        <b>User stage</b>: {userStage}
                    </div>
                    <div>
                        <b>Website stage</b>: {websiteStage}
                    </div>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        onClick={redirectToStagingWebsite}
                        loading={redirecting}
                    >
                        Redirect me to the correct website
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <AppBar />
            {loadingData && !showRedirectDialog ? (
                <div className="fixed top-32 w-full flex flex-row justify-center pt-10">
                    <Loading />
                </div>
            ) : (
                <div className="w-full flex flex-row flex-grow min-h-0">
                    {loginState === Authentication.LOGIN_STATUS_LOGGED_IN && (
                        <SideMenu />
                    )}
                    <div className="flex flex-col fixed left-96 top-56 right-0 bottom-0 overflow-x-auto">
                        <InterceptSwitch routes={routes} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default App;
