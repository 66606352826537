import * as React from "react";
import { Collapse, List, ListItemButton, ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { sideMenuStructure } from "../routes";

/**
 * Expendable side menu item that contains other side menu items
 * @param title title
 * @param children
 */
function ExpendableSideMenuItem({ title, children }) {
    const location = useLocation();
    const subMenuItemPaths = React.Children.map(children, (c) => c.props.path);
    // See if menu should be expanded by default, in case our current URL is equal to the path of
    // its child sub-menu items.
    const [isOpen, setIsOpen] = useState(
        subMenuItemPaths.includes(location.pathname),
    );
    // Render all sub menu items with an additional indentation level
    const newChildren = React.Children.map(children, (c) =>
        React.cloneElement(c, { level: 1 }),
    );

    // An expendable side menu item, containing other side menu items
    return (
        <>
            <ListItemButton onClick={() => setIsOpen(!isOpen)}>
                <ListItemText primary={title} />
                {isOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {newChildren}
                </List>
            </Collapse>
        </>
    );
}

/**
 * Side menu item
 * @param title title
 * @param path path/URL
 * @param level indentation level (zero = no indentation)
 */
function SideMenuItem({ title, path, level = 0, children = [] }) {
    const location = useLocation();

    return (
        <ListItemButton
            sx={{ pl: 4 * level }}
            selected={location.pathname === path}
            component={Link}
            to={path}
        >
            <ListItemText primary={title} />
        </ListItemButton>
    );
}

/**
 * Side menu - renders the side menu of IRIS according to sideMenuStructure,
 * defined in routes.js
 */
export default function SideMenu() {
    return (
        <div className="flex flex-col w-96 fixed left-0 top-56">
            <List>
                {sideMenuStructure.map((parent) => (
                    <ExpendableSideMenuItem
                        key={parent.title}
                        title={parent.title}
                    >
                        {parent.children.map((child) => (
                            <SideMenuItem
                                key={child.title}
                                title={child.title}
                                path={child.path}
                            />
                        ))}
                    </ExpendableSideMenuItem>
                ))}
            </List>
        </div>
    );
}
