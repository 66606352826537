import * as React from "react";
import { useSelector } from "react-redux";
import DataTable, {
    columnStyle,
    customValueRenderer,
    dateFilterColumn,
    DISPLAY_DATE_FORMAT,
    dropDownColumn,
    freeTextColumn,
} from "../../components/DataTable";
import { DateTime } from "luxon";
import { getCountryName } from "../../utils/countries";
import { API_DATE_FORMAT } from "../../hooks/useLoadAllData";
import { getExchangeName } from "../../store/exchanges";

const TABLE_OPTIONS = {
    sortOrder: {
        name: "name",
        direction: "asc",
    },
};

export default function ManagedExchanges() {
    const exchanges = useSelector((state) => state.exchanges.managedExchanges);
    const today = DateTime.now().toFormat(API_DATE_FORMAT);
    const tomorrow = DateTime.local()
        .plus({ days: 1 })
        .toFormat(API_DATE_FORMAT);
    const yesterday = DateTime.local()
        .minus({ days: 1 })
        .toFormat(API_DATE_FORMAT);

    // Renders active trade day - if true, with red background; otherwise with green background
    const renderTradeDate = (v) => (v ? "Open" : "Closed");
    const tradeDateColumnStyle = (value) => {
        return {
            className: `${
                value === "Open" ? "bg-green-500" : "bg-red-500"
            } p-0 text-center`,
        };
    };

    let columns = [
        freeTextColumn("name", "Name", {
            ...customValueRenderer((v, index) => {
                // Show all exchange names (including alternative names)
                const exchange = exchanges[index];
                return getExchangeName(exchange);
            }, true),
        }),
        freeTextColumn(
            "country_code",
            "Country",
            customValueRenderer(getCountryName),
        ),
    ];

    if (exchanges.length > 0) {
        // Dynamically add the columns for the trade dates returned by the API

        // Parse and sort trade dates
        const tradeDates = Object.keys(exchanges[0].trade_dates)
            .map((d) => DateTime.fromFormat(d, API_DATE_FORMAT))
            .sort();

        // Create a column for each trade day
        const tradeDateColumns = tradeDates.map((d) => {
            const key = d.toFormat(API_DATE_FORMAT);
            let display = d.toFormat(DISPLAY_DATE_FORMAT);

            if (key.localeCompare(yesterday) === 0) {
                display = `Yesterday (${display})`;
            } else if (key.localeCompare(today) === 0) {
                display = `Today (${display})`;
            } else if (key.localeCompare(tomorrow) === 0) {
                display = `Tomorrow (${display})`;
            }

            return dropDownColumn(`trade_dates.${key}`, display, {
                ...customValueRenderer(renderTradeDate),
                ...columnStyle(tradeDateColumnStyle),
            });
        });
        columns = [...columns, ...tradeDateColumns];

        // The last column should be the "Next Trade Date" column
        columns.push(dateFilterColumn("next_trade_date", "Next Trade Date"));
    }

    return (
        <>
            <DataTable
                title={"Managed Exchanges"}
                data={exchanges}
                columns={columns}
                options={TABLE_OPTIONS}
            />
        </>
    );
}
