import * as React from "react";
import Title from "../components/Title";
import { Button, CircularProgress, TextField } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Authentication from "../modules/authentication";
import ErrorLabel from "../components/Error";

export default function Login() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const verifyForm = email && password;

    const login = () => {
        setLoading(true);
        setError(null);

        // Login with username/password combo
        Authentication.loginWithPassword(
            email.toLowerCase().trim(),
            password,
            () => {
                setLoading(false);
                navigate("/");
            },
            (err) => {
                // Couldn't login
                setLoading(false);
                console.error(err);
                if (err.code === "UserNotFoundException") {
                    setError("Email not found");
                } else if (err.code === "NotAuthorizedException") {
                    setError("Invalid password");
                } else {
                    setError(err.message);
                }
            },
        );
    };

    return (
        <div className="w-96 flex flex-col">
            <Title>Login</Title>
            <TextField
                id="standard-basic"
                label="Email"
                variant="standard"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
                className="mt-10"
                id="standard-basic"
                label="Password"
                variant="standard"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={(e) => {
                    if (e.key === "Enter" && verifyForm) login();
                }}
            />

            <Button
                className="w-48 mt-10"
                variant="contained"
                onClick={login}
                disabled={!verifyForm || loading}
            >
                {loading ? (
                    <CircularProgress color="secondary" size={25} />
                ) : (
                    "Login"
                )}
            </Button>
            {error && <ErrorLabel>{error}</ErrorLabel>}
            <Link to="/register" className="mt-5 font-bold">
                Register instead?
            </Link>
        </div>
    );
}
