import * as React from "react";
import {
    convertToPercent,
    dateFilterColumn,
    multiSelectColumn,
    nonFilterableColumn,
    roundNumbers,
} from "../../components/DataTable";
import { DateField } from "../../components/Form";
import QueryDataTable from "../../components/QueryDataTable";
import { DateTime } from "luxon";

export default function ImpliedInflation() {
    const QUERY_FIELDS = [
        {
            name: "start_date",
            label: "Start Date",
            type: DateField,
            required: true,
            defaultValue: DateTime.now()
                .startOf("day")
                .minus({ days: 1 })
                .toMillis(),
        },
        {
            name: "end_date",
            label: "End Date",
            type: DateField,
            required: true,
            defaultValue: DateTime.now()
                .startOf("day")
                .minus({ days: 1 })
                .toMillis(),
        },
    ];

    const TABLE_COLUMNS = [
        dateFilterColumn("date", "Date"),
        multiSelectColumn("duration", "Duration", roundNumbers(2)),
        nonFilterableColumn("ils_yield", "ILS Yield", convertToPercent(2)),
        nonFilterableColumn("cpi_yield", "CPI Yield", convertToPercent(2)),
        nonFilterableColumn(
            "implied_inflation",
            "Implied Inflation",
            convertToPercent(2),
        ),
    ];

    const TABLE_OPTIONS = {
        sortOrder: {
            name: "date",
            direction: "asc",
        },
    };

    const enrichResults = (results) => {
        return results.map((x) => ({
            ...x,
            implied_inflation: x.ils_yield - x.cpi_yield,
        }));
    };

    return (
        <>
            <QueryDataTable
                queryFields={QUERY_FIELDS}
                queryAPIPath="implied_inflation"
                tableColumns={TABLE_COLUMNS}
                tableOptions={TABLE_OPTIONS}
                dynamicLoad={true}
                preprocessResults={enrichResults}
            />
        </>
    );
}
