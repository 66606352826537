import FileUploader from "./FileUploader";
import { useEffect, useState } from "react";
import { Button, CircularProgress, Dialog } from "@mui/material";
import Authentication from "../modules/authentication";
import DataTable from "./DataTable";

const gApiClient = Authentication.getAPIClient();

/**
 * File importer dialog component - pop-up dialog that allows the user to upload a file,
 * then calls an API to upload that file (while displaying a progress dialog), and finally
 * showing the response.
 *
 * @param show Should the dialog be shown
 * @param onCancelled When the user cancels the dialog without choosing a file
 * @param onFinished a callback function, called when the file was imported successfully.
 * @param title Title of the dialog
 * @param uploadAPIPath The API path to call for importing the file (e.g. 'import_corporate_actions')
 * @param confirmButtonText Text of the confirm/OK button of the dialog
 * @param cancelButtonText Text of the cancel button of the dialog
 * @param closeButtonText Text of the close button of the dialog
 * @param importsText Text that appears above the table showing the list of successful records imported
 * @param uploadAgainButtonText Text of the 'Upload Another File' button of the dialog
 * @param importsTableColumns list of columns - used when displaying the imported items (e.g. CAs) - see `DataTable` component
 * @param importsTableOptions table options - used when displaying the imported items (e.g. CAs) - see `DataTable` component
 * @param transformChanges (optional) callback function that receives a single result row from the import, and transforms
 *      it - e.g. adds an index property to an IA result
 */
export default function FileImporterDialog({
    show,
    onCancelled,
    onFinished,
    title,
    uploadAPIPath,
    importsTableColumns,
    importsTableOptions = {},
    transformChanges = (c) => c,
    confirmButtonText = "Upload",
    cancelButtonText = "Cancel",
    closeButtonText = "Close",
    importsText = "Successful Imports",
    uploadAgainButtonText = "Upload Another File",
}) {
    const [chosenFilename, setChosenFilename] = useState(null);
    const [chosenFileBody, setChosenFileBody] = useState(null);
    const [importing, setImporting] = useState(false);
    const [finished, setFinished] = useState(false);
    const [errors, setErrors] = useState([]);
    const [changes, setChanges] = useState([]);

    const resetDialog = () => {
        setChosenFileBody(null);
        setChosenFilename(null);
        setImporting(false);
        setFinished(false);
        setErrors([]);
        setChanges([]);
    };

    useEffect(() => {
        if (show) {
            // Dialog was re-shown - Reset everything
            resetDialog();
        }
    }, [show]);

    const onCurrentFileChosen = (filename, fileBody) => {
        setChosenFilename(filename);
        setChosenFileBody(fileBody);
    };

    const onUploadFile = async () => {
        setImporting(true);

        // Call the API for uploading the file
        const fileExtension = chosenFilename
            .substring(chosenFilename.lastIndexOf(".") + 1)
            .toLowerCase();

        try {
            const response = await gApiClient.callApi(uploadAPIPath, {
                method: "POST",
                params: {
                    file_type: ["xls", "xlsx"].includes(fileExtension)
                        ? "xls"
                        : "csv",
                },
                queryParams: true,
                body: chosenFileBody,
            });
            const responseData = response.data;

            if (responseData.errors) {
                setErrors(responseData.errors);
            }
            if (responseData.changes) {
                setChanges(
                    responseData.changes.map((c) => transformChanges(c)),
                );
            }
        } catch (exc) {
            console.error(exc);
            setErrors([`Network Error: ${exc.message}`]);
        }

        setImporting(false);
        setFinished(true);
    };

    return (
        <Dialog
            open={show}
            onClose={() => {
                if (!importing) onCancelled();
            }}
            maxWidth={finished ? "md" : "sm"}
            fullWidth={true}
        >
            <div className="p-5 flex flex-col items-center">
                <div className="text-center mb-4 text-3xl">{title}</div>
                {importing && (
                    <div className="flex flex-col p-10 text-center items-center">
                        <CircularProgress color="secondary" size={55} />
                        <div className="mt-5 text-xl">Importing...</div>
                    </div>
                )}
                {!importing && !finished && (
                    <>
                        <FileUploader onFileChosen={onCurrentFileChosen} />
                        <div className="flex flex-row justify-center pt-4 gap-4">
                            <Button
                                variant="contained"
                                disabled={!chosenFilename}
                                onClick={onUploadFile}
                            >
                                {confirmButtonText}
                            </Button>
                            <Button variant="outlined" onClick={onCancelled}>
                                {cancelButtonText}
                            </Button>
                        </div>
                    </>
                )}
                {errors.length > 0 && (
                    <div className="mb-10 mt-10">
                        <div className="font-bold text-red-500">Errors:</div>
                        <div className="max-h-[200px] overflow-x-hidden">
                            {errors.map((e) => (
                                <div key={e}>• {e}</div>
                            ))}
                        </div>
                    </div>
                )}
                {changes.length > 0 && (
                    <div className="mb-10 mt-10 max-w-full">
                        <div className="font-bold mb-10">{importsText}:</div>
                        <div className="overflow-y-hidden">
                            <DataTable
                                title={"Corporate Actions"}
                                data={changes}
                                columns={importsTableColumns}
                                options={importsTableOptions}
                            />
                        </div>
                    </div>
                )}
                {finished && (
                    <>
                        <div className="flex flex-row justify-center pt-4 gap-4">
                            <Button variant="contained" onClick={resetDialog}>
                                {uploadAgainButtonText}
                            </Button>
                            <Button variant="outlined" onClick={onFinished}>
                                {closeButtonText}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </Dialog>
    );
}
