import * as React from "react";
import Authentication from "../modules/authentication";
import { Dialog } from "@mui/material";
import Form from "./Form";
import { useEffect, useState } from "react";

const gApiClient = Authentication.getAPIClient();

/**
 * A dialog (popup) with a form that adds/edits a record (e.g. managed index).
 * In charge of filling out the initial form, validating input and calling the API to remotely add/edit the record.
 *
 * @param show Should the dialog be shown
 * @param record The record to edit/update (assumes it has a standard '_id' field) - if not provided, assumes
 *      it's a record creation dialog
 * @param fields Array of fields to display/edit for the record - see `Form` component for details.
 * @param title Title of the dialog
 * @param onCancelled When the user cancels the dialog without adding the record
 * @param onFinished a callback function, called when the record was created/updated successfully -
 *  receives the created/updated record as argument.
 * @param apiPath The API path to call for creating/updating the record (e.g. 'managed_security') -
 *      assumes it's a POST HTTP endpoint, where the HTTP request body is JSON formatted.
 * @param apiHTTPMethod POST/PUT/etc
 * @param confirmButtonText Text of the confirm/OK button of the dialog
 * @param cancelButtonText Text of the cancel button of the dialog
 * @param idFieldName (optional, default="_id") what is the field name that uniquely identifies the record -
 *      in case of records with historical updates (e.g. managed securities), it's internal_id, not _id.
 */
function BaseRecordDialog({
    record = null,
    fields,
    show,
    onCancelled,
    onFinished,
    title,
    apiPath,
    apiHTTPMethod,
    confirmButtonText,
    cancelButtonText,
    idFieldName = "_id",
}) {
    const [callingAPI, setCallingAPI] = useState(false);

    // Add default values to the fields - so we'll show the current record's values, when first showing the form
    const fieldsToDisplay = record
        ? fields.map((f) => {
              // User can also provide default values of their own, so we won't take it from the current record's value
              if (f.defaultValue) return f;

              return {
                  ...f,
                  defaultValue:
                      record && f.name in record ? record[f.name] : null,
              };
          })
        : fields;

    const resetDialog = () => {
        setCallingAPI(false);
    };

    useEffect(() => {
        if (show) {
            // Dialog was re-shown - Reset everything
            resetDialog();
        }
    }, [show]);

    const url = record ? `${apiPath}/${record[idFieldName]}` : apiPath;

    const onSubmit = async (values) => {
        const response = await gApiClient.callApi(url, {
            method: apiHTTPMethod,
            body: values,
        });

        // Return created record
        onFinished(response.data);

        setCallingAPI(false);
    };

    return (
        <Dialog
            open={show}
            onClose={() => {
                if (!callingAPI) onCancelled();
            }}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <div className="p-5 flex flex-col">
                <div className="text-center mb-8 text-3xl">{title}</div>
                <Form
                    fields={fieldsToDisplay}
                    onSubmit={onSubmit}
                    submitButtonText={confirmButtonText}
                    additionalButtons={[
                        { text: cancelButtonText, onPress: onCancelled },
                    ]}
                    className="flex-col"
                    buttonsClassName="justify-center"
                />
            </div>
        </Dialog>
    );
}

/**
 * A dialog (popup) with a form that adds a record (e.g. managed index).
 * In charge of filling out the initial form, validating input and calling the API to remotely add the record.
 *
 * @param show Should the dialog be shown
 * @param fields Array of fields to display/edit for the record - see `Form` component for details.
 * @param title Title of the dialog
 * @param onCancelled When the user cancels the dialog without adding the record
 * @param onFinished a callback function, called when the record was created successfully - receives the created record
 *      as argument.
 * @param createAPIPath The API path to call for updating the record (e.g. 'managed_security') -
 *      assumes it's a POST HTTP endpoint, where the HTTP request body is JSON formatted.
 * @param confirmButtonText Text of the confirm/OK button of the dialog
 * @param cancelButtonText Text of the cancel button of the dialog
 */
export function AddRecordDialog({
    fields,
    show,
    onCancelled,
    onFinished,
    title,
    createAPIPath,
    confirmButtonText = "Add",
    cancelButtonText = "Cancel",
}) {
    return (
        <BaseRecordDialog
            show={show}
            fields={fields}
            onCancelled={onCancelled}
            onFinished={onFinished}
            title={title}
            apiPath={createAPIPath}
            apiHTTPMethod={"POST"}
            confirmButtonText={confirmButtonText}
            cancelButtonText={cancelButtonText}
        />
    );
}

/**
 * A dialog (popup) with a form that edits/updates a record (e.g. corporate action, managed security).
 * In charge of filling out the initial form, validating input and calling the API to remotely update the record.
 *
 * @param show Should the dialog be shown
 * @param record The record to edit/update (assumes it has a standard '_id' field)
 * @param fields Array of fields to display/edit for the record - see `Form` component for details.
 * @param title Title of the dialog
 * @param onCancelled When the user cancels the dialog without editing the record
 * @param onFinished a callback function, called when the record was updated successfully - receives the updated record
 *      as argument.
 * @param updateAPIPath The API path to call for updating the record (e.g. 'corporate_action') -
 *      assumes it's a PUT HTTP endpoint, where the HTTP request body is JSON formatted.
 * @param confirmButtonText Text of the confirm/OK button of the dialog
 * @param cancelButtonText Text of the cancel button of the dialog
 * @param idFieldName (optional, default="_id") what is the field name that uniquely identifies the record -
 *      in case of records with historical updates (e.g. managed securities), it's internal_id, not _id.
 */
export function EditRecordDialog({
    record,
    fields,
    show,
    onCancelled,
    onFinished,
    title,
    updateAPIPath,
    confirmButtonText = "Update",
    cancelButtonText = "Cancel",
    idFieldName = "_id",
}) {
    return (
        <BaseRecordDialog
            show={show}
            fields={fields}
            onCancelled={onCancelled}
            onFinished={onFinished}
            title={title}
            record={record}
            apiPath={updateAPIPath}
            apiHTTPMethod={"PUT"}
            confirmButtonText={confirmButtonText}
            cancelButtonText={cancelButtonText}
            idFieldName={idFieldName}
        />
    );
}
