import * as React from "react";
import { DateField, SelectField } from "../../components/Form";
import QueryDataTable from "../../components/QueryDataTable";
import {
    customValueRenderer,
    dateFilterColumn,
    freeTextColumn,
    multiSelectColumn,
} from "../../components/DataTable";
import { useSelector } from "react-redux";
import { useState } from "react";

export default function APIRequests() {
    const [period, setPeriod] = useState();
    const clientsById = useSelector((state) => state.reports.reportClientsById);

    const QUERY_FIELDS = [
        {
            name: "start_date",
            label: "Start Date",
            type: DateField,
            required: true,
        },
        {
            name: "end_date",
            label: "End Date",
            type: DateField,
            required: true,
        },
        {
            name: "period",
            label: "Period",
            type: SelectField,
            options: [
                { id: "daily", label: "Daily" },
                { id: "weekly", label: "Weekly" },
                { id: "monthly", label: "Monthly" },
            ],
            required: true,
        },
        {
            name: "client_id",
            label: "Client",
            type: SelectField,
            optionsFromSelector: (state) =>
                state.reports.reportClients.map((s) => ({
                    id: s._id,
                    label: s.name,
                })),
            required: false,
        },
        {
            name: "report_type",
            label: "Report Type",
            type: SelectField,
            optionsFromSelector: (state) =>
                state.reports.reportTypes.map((s) => ({
                    id: s,
                    label: s,
                })),
            required: false,
        },
    ];

    const getPeriodFormat = () => {
        if (period === "daily") {
            return "dd/MM/yyyy";
        } else if (period === "weekly") {
            return "'Week of' dd/MM/yyyy";
        } else if (period === "monthly") {
            return "MMMM yyyy";
        }
    };

    const TABLE_COLUMNS = [
        dateFilterColumn("date", "Date", { dateFormat: getPeriodFormat() }),
        multiSelectColumn("client_id", "Client", {
            ...customValueRenderer((x) => clientsById[x].name),
        }),
        multiSelectColumn("report_type", "Report Type"),
        freeTextColumn("count", "Count"),
    ];

    const TABLE_OPTIONS = {
        sortOrder: {
            name: "date",
            direction: "asc",
        },
    };

    const onQuerySet = (values) => {
        setPeriod(values.period);
    };

    return (
        <>
            <QueryDataTable
                queryFields={QUERY_FIELDS}
                queryAPIPath="report_requests"
                tableColumns={TABLE_COLUMNS}
                tableOptions={TABLE_OPTIONS}
                onQuerySet={onQuerySet}
            />
        </>
    );
}
