import * as React from "react";
import {
    columnWidth,
    dateFilterColumn,
    multiSelectColumn,
    nonFilterableColumn,
} from "../../components/DataTable";
import { DateField, MultipleCurrenciesField } from "../../components/Form";
import QueryDataTable from "../../components/QueryDataTable";

const TABLE_OPTIONS = {
    sortOrder: {
        name: "date",
        direction: "asc",
    },
};

export default function FXRates() {
    const COLUMNS = [
        dateFilterColumn("date", "Date"),
        multiSelectColumn(
            "from_currency",
            "From Currency",
            columnWidth("50px"),
        ),
        multiSelectColumn("to_currency", "To Currency", columnWidth("50px")),
        nonFilterableColumn("close_price", "Close Price", columnWidth("100px")),
    ];

    const QUERY_FIELDS = [
        {
            name: "start_date",
            label: "Start Date",
            type: DateField,
            required: true,
        },
        {
            name: "end_date",
            label: "End Date",
            type: DateField,
            required: true,
        },
        {
            name: "from_currencies",
            label: "From Currencies",
            type: MultipleCurrenciesField,
        },
        {
            name: "to_currencies",
            label: "To Currencies",
            type: MultipleCurrenciesField,
        },
    ];

    return (
        <>
            <QueryDataTable
                tableColumns={COLUMNS}
                tableOptions={TABLE_OPTIONS}
                queryFields={QUERY_FIELDS}
                queryAPIPath="fx_rates"
            />
        </>
    );
}
