import countryLookup from "country-code-lookup";

/**
 * Utility functions for anything country-related
 */

/** Returns country name by ISO code */
export const getCountryName = (x) =>
    x ? countryLookup.byIso(x).country : "Unknown";

export const getAllCountries = () => countryLookup.countries;
