import * as React from "react";
import { useSelector } from "react-redux";
import {
    columnStyle,
    customValueRenderer,
    dateFilterColumn,
    dropDownColumn,
    freeTextColumn,
} from "../../components/DataTable";
import { getCountryName } from "../../utils/countries";
import { getExchangeName } from "../../store/exchanges";
import {
    DateField,
    MultipleCountriesField,
    MultipleExchangesField,
} from "../../components/Form";
import QueryDataTable from "../../components/QueryDataTable";

const TABLE_OPTIONS = {
    sortOrder: {
        name: "exchange.name",
        direction: "asc",
    },
};

export default function ExchangeTradeDates() {
    const exchangesById = useSelector((state) => state.exchanges.exchangesById);

    // Renders trade day - if not trading, with red background; otherwise with green background
    const renderTradeDate = (v) => (v ? "Open" : "Closed");
    const tradeDateColumnStyle = (value) => {
        return {
            className: `${
                value === "Open" ? "bg-green-500" : "bg-red-500"
            } p-0 text-center`,
        };
    };

    let columns = [
        freeTextColumn(
            "exchange",
            "Name",
            customValueRenderer((x) => getExchangeName(x)),
        ),
        freeTextColumn(
            "exchange.country_code",
            "Country",
            customValueRenderer((x) => getCountryName(x)),
        ),
        dateFilterColumn("date", "Date"),
        dropDownColumn("has_trade", "Has Trade?", {
            ...customValueRenderer(renderTradeDate),
            ...columnStyle(tradeDateColumnStyle),
        }),
    ];

    const QUERY_FIELDS = [
        {
            name: "start_date",
            label: "Start Date",
            type: DateField,
            required: true,
        },
        {
            name: "end_date",
            label: "End Date",
            type: DateField,
            required: true,
        },
        {
            name: "exchanges",
            label: "Exchanges",
            type: MultipleExchangesField,
        },
        {
            name: "countries",
            label: "Countries",
            type: MultipleCountriesField,
        },
    ];

    const enrichResults = (results) => {
        return results.map((x) => ({
            ...x,
            exchange: exchangesById[x.exchange_id],
        }));
    };

    return (
        <>
            <QueryDataTable
                tableColumns={columns}
                tableOptions={TABLE_OPTIONS}
                queryFields={QUERY_FIELDS}
                preprocessResults={enrichResults}
                queryAPIPath="exchange_trade_dates"
            />
        </>
    );
}
