import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    reportTypes: [],
    reportClients: [],
    reportClientsById: {},
};

export const reportsSlice = createSlice({
    name: "reports",
    initialState,
    reducers: {
        setReportTypes: (state, action) => {
            // Case-insensitive sorting
            state.reportTypes = action.payload.sort((x, y) =>
                x.toLowerCase().localeCompare(y.toLowerCase()),
            );
        },
        setReportClients: (state, action) => {
            // Sort by client name
            state.reportClients = action.payload.sort((x, y) =>
                x.name.toLowerCase().localeCompare(y.name.toLowerCase()),
            );
            state.reportClientsById = Object.fromEntries(
                action.payload.map((e) => [e._id, e]),
            );
        },
    },
});

export const { setReportTypes, setReportClients } = reportsSlice.actions;
export default reportsSlice.reducer;
