import * as React from "react";
import { useSelector } from "react-redux";
import {
    customValueRenderer,
    dateFilterColumn,
    multiSelectColumn,
    nonFilterableColumn,
    thousandsSeparatorRound,
    roundNumbers,
    convertToPercent,
} from "../../components/DataTable";
import QueryDataTable from "../../components/QueryDataTable";
import {
    BooleanField,
    CurrencyField,
    DateField,
    SelectField,
    TextField,
} from "../../components/Form";
import { DateTime } from "luxon";
import { INDEX_TYPE, SECURITIES_TYPE, TRIGGER_TYPE } from "./ManagedIndexes";

export default function IndexesLevels() {
    const indexesById = useSelector((state) => state.indexes.indexesById);

    const QUERY_FIELDS = [
        {
            name: "start_date",
            label: "Start Date",
            type: DateField,
            required: true,
            defaultValue: DateTime.now()
                .startOf("day")
                .minus({ days: 1 })
                .toMillis(),
        },
        {
            name: "end_date",
            label: "End Date",
            type: DateField,
            required: true,
            defaultValue: DateTime.now()
                .startOf("day")
                .minus({ days: 1 })
                .toMillis(),
        },
        {
            name: "name",
            label: "Name",
            type: TextField,
        },
        {
            name: "internal_id",
            label: "Internal ID",
            type: TextField,
        },
        {
            name: "type",
            label: "Type",
            type: SelectField,
            options: Object.keys(INDEX_TYPE).map((k) => ({
                id: k,
                label: INDEX_TYPE[k],
            })),
        },
        {
            name: "currency",
            label: "Currency",
            type: CurrencyField,
        },
        {
            name: "securities_type",
            label: "Securities Type",
            type: SelectField,
            options: Object.keys(SECURITIES_TYPE).map((k) => ({
                id: k,
                label: SECURITIES_TYPE[k],
            })),
        },
        {
            name: "has_product",
            label: "Has Product",
            type: BooleanField,
            allowUnset: true,
        },
        {
            name: "isin",
            label: "ISIN",
            type: TextField,
        },
        {
            name: "bloomberg_ticker",
            label: "Bloomberg Ticker",
            type: TextField,
        },
        {
            name: "ric",
            label: "RIC",
            type: TextField,
        },
        {
            name: "trigger_type",
            label: "Trigger Type",
            type: SelectField,
            options: Object.keys(TRIGGER_TYPE).map((k) => ({
                id: k,
                label: TRIGGER_TYPE[k],
            })),
        },
        {
            name: "disabled_from",
            label: "Disabled From",
            type: DateField,
        },
    ];

    const TABLE_COLUMNS = [
        dateFilterColumn("date", "Date"),
        multiSelectColumn("index_name", "Index Name"),
        multiSelectColumn("index_internal_id", "Internal ID"),
        multiSelectColumn(
            "index_type",
            "Index Type",
            customValueRenderer((v) => INDEX_TYPE[v]),
        ),
        nonFilterableColumn("level", "Level", {
            downloadOriginal: true,
        }),
        nonFilterableColumn("divisor_open", "Divisor Open", {
            downloadOriginal: true,
            ...thousandsSeparatorRound(100),
        }),
        nonFilterableColumn("ttm", "TTM", {
            downloadOriginal: true,
            ...thousandsSeparatorRound(100),
        }),
        nonFilterableColumn("divisor_close", "Divisor Close", {
            downloadOriginal: true,
            ...thousandsSeparatorRound(100),
        }),
        nonFilterableColumn("ttm_next_day", "TTM Next Day", {
            downloadOriginal: true,
            ...thousandsSeparatorRound(100),
        }),
        nonFilterableColumn("divisor_next_open", "Divisor Next Open", {
            downloadOriginal: true,
            ...thousandsSeparatorRound(100),
        }),
        nonFilterableColumn("prev_level", "Prev Level"),
        nonFilterableColumn("daily_change", "Daily Change", convertToPercent()),
        nonFilterableColumn("sharp_change", "Sharp Change", convertToPercent()),
        nonFilterableColumn("duration", "Duration"),
        nonFilterableColumn(
            "internal_yield_cpi",
            "Internal Yield CPI",
            convertToPercent(),
        ),
        nonFilterableColumn(
            "internal_yield_ils",
            "Internal Yield ILS",
            convertToPercent(),
        ),
        nonFilterableColumn(
            "internal_yield",
            "Internal Yield",
            convertToPercent(),
        ),
        nonFilterableColumn(
            "government_spread",
            "Government Spread",
            convertToPercent(),
        ),
        nonFilterableColumn("government_spread", "Government Spread BPS"),
        multiSelectColumn(
            "rating",
            "Rating",
            customValueRenderer((v) => (v ? `il${v}` : "")),
        ),
        nonFilterableColumn(
            "next_open_internal_yield_cpi",
            "Next Open Internal Yield CPI",
            convertToPercent(),
        ),
        nonFilterableColumn(
            "next_open_internal_yield_ils",
            "Next Open Internal Yield ILS",
            convertToPercent(),
        ),
        nonFilterableColumn(
            "next_open_government_spread",
            "Next Open Government Spread",
            convertToPercent(),
        ),
        multiSelectColumn(
            "next_open_rating",
            "Next Open Rating",
            customValueRenderer((v) => (v ? `il${v}` : "")),
        ),
        nonFilterableColumn(
            "dividend_yield",
            "Dividend Yield",
            convertToPercent(),
        ),
        nonFilterableColumn("pe", "PE"),
        nonFilterableColumn("pb", "PB"),
        nonFilterableColumn("base_ttm", "Base TTM", { display: false }),
        nonFilterableColumn(
            "base_internal_yield_cpi",
            "Base Internal Yield CPI",
            {
                display: false,
            },
        ),
        nonFilterableColumn(
            "base_internal_yield_ils",
            "Base Internal Yield ILS",
            {
                display: false,
            },
        ),
        nonFilterableColumn(
            "base_government_spread",
            "Base Government Spread",
            {
                display: false,
            },
        ),
        nonFilterableColumn("base_rating", "Base Rating", { display: false }),
        nonFilterableColumn("base_divisor", "Base Divisor", { display: false }),
    ];

    const TABLE_OPTIONS = {
        sortOrder: {
            name: "date",
            direction: "asc",
        },
    };

    // daily_change
    const enrichResults = (results) => {
        return results.map((x) => ({
            ...x,
            index_name: indexesById[x.index_id].name,
            index_internal_id: indexesById[x.index_id].internal_id,
            index_type: indexesById[x.index_id].type,
        }));
    };

    return (
        <>
            <QueryDataTable
                queryFields={QUERY_FIELDS}
                queryAPIPath="index_performances"
                tableColumns={TABLE_COLUMNS}
                tableOptions={TABLE_OPTIONS}
                preprocessResults={enrichResults}
            />
        </>
    );
}
