import * as React from "react";
import { useSelector } from "react-redux";
import {
    booleanValueRenderer,
    columnWidth,
    customValueRenderer,
    dateFilterColumn,
    dropDownColumn,
    freeTextColumn,
    multiSelectColumn,
    nonFilterableColumn,
} from "../../components/DataTable";
import FileImporterDialog from "../../components/FileImporterDialog";
import { useCallback, useMemo, useRef, useState } from "react";
import { FileUpload } from "@mui/icons-material";
import {
    BooleanField,
    CurrencyField,
    DateField,
    DecimalField,
    FloatField,
    MultipleSecuritiesField,
    MultipleSelectField,
    SecurityField,
    TextField,
} from "../../components/Form";
import { getSecurityName } from "../../store/securities";
import QueryDataTable from "../../components/QueryDataTable";
import { IconButton, Tooltip } from "@mui/material";
import { EditRecordDialog } from "../../components/RecordDialog";

const CA_EVENT_TYPE = {
    capital_repayment: "Capital Repayment",
    cash_dividend: "Cash Dividend",
    share_split: "Share Split",
    special_price: "Special Price",
    cash_dividend_with_stock_alternative:
        "Cash Dividend with Stock Alternative",
    stock_dividend_with_cash_alternative:
        "Stock Dividend with Cash Alternative",
    special_dividend: "Special Dividend",
    stock_dividend: "Stock Dividend",
    share_consolidation: "Share Consolidation",
    capital_reduction: "Capital Reduction",
    scrip_issue: "Scrip Issue",
    bonus_issue: "Bonus Issue",
    demerger: "Demerger",
    rights_issue: "Rights Issue",
    rights_issue_price_only: "Rights Issue Price Only",
    priority_issue: "Priority Issue",
    trading_halt: "Trading Halt",
    delisting: "Delisting",
    exchange_offer: "Exchange Offer",
    merger: "Merger",
    delisting_at_close_price: "Delisting at Close Price",
    new_stock_distribution: "New Stock Distribution",
    cash_coupon: "Cash Coupon",
    sinkable_funds: "Sinkable Funds",
    override_next_open_price: "Override Next Open Price",
};

// Fields that the user is allowed to edit per CA
const EDIT_FIELDS = [
    {
        name: "execution_date",
        label: "Execution Date",
        type: DateField,
        required: true,
    },
    {
        name: "share_adjustment",
        label: "Share Adjustment",
        type: FloatField,
    },
    {
        name: "price_adjustment",
        label: "Price Adjustment",
        type: DecimalField,
    },
    {
        name: "currency",
        label: "Currency",
        type: CurrencyField,
        required: true,
    },
    {
        name: "linked_security_id",
        label: "Linked Security",
        type: SecurityField,
    },
    {
        name: "apply_dividend_tax",
        label: "Apply Dividend Tax?",
        type: BooleanField,
    },
    {
        name: "note",
        label: "Note",
        type: TextField,
    },
];

export default function CorporateActions() {
    const [showImportDialog, setShowImportDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [editRowIndex, setEditRowIndex] = useState(0);
    const [corporateActions, setCorporateActions] = useState([]);
    const securitiesById = useSelector(
        (state) => state.securities.securitiesById,
    );
    const indexesById = useSelector((state) => state.indexes.indexesById);
    const queryDataTable = useRef();

    const TABLE_OPTIONS = useMemo(() => {
        return {
            sortOrder: {
                name: "execution_date",
                direction: "asc",
            },
            onEditRow: (index) => {
                setEditRowIndex(index);
                setShowEditDialog(true);
            },
        };
    }, []);

    const COLUMNS = useMemo(() => {
        return [
            freeTextColumn(
                "security_id",
                "Security",
                customValueRenderer((x) => getSecurityName(securitiesById[x])),
            ),
            multiSelectColumn(
                "event_type",
                "CA Type",
                customValueRenderer((x) => CA_EVENT_TYPE[x]),
            ),
            dateFilterColumn("announcement_date", "Announcement Date"),
            dateFilterColumn("execution_date", "Execution Date"),
            nonFilterableColumn(
                "share_adjustment",
                "Share Adjustment",
                columnWidth("100px"),
            ),
            nonFilterableColumn(
                "price_adjustment",
                "Price Adjustment",
                columnWidth("100px"),
            ),
            multiSelectColumn("currency", "Currency", columnWidth("50px")),
            freeTextColumn(
                "linked_security_id",
                "Linked Security",
                customValueRenderer((x) =>
                    x ? getSecurityName(securitiesById[x]) : "",
                ),
            ),
            multiSelectColumn("index_internal_id", "Index ID"),
            dropDownColumn("apply_dividend_tax", "Apply Dividend Tax", {
                ...booleanValueRenderer(),
                ...columnWidth("50px"),
            }),
            dropDownColumn("applied", "Applied", {
                ...booleanValueRenderer(),
                ...columnWidth("50px"),
            }),
            dropDownColumn("is_disabled", "Disabled", {
                ...booleanValueRenderer(),
                ...columnWidth("50px"),
            }),
            nonFilterableColumn("note", "Note"),
        ];
    }, [securitiesById]);

    const QUERY_FIELDS = [
        {
            name: "start_date",
            label: "Execution Date (Start)",
            type: DateField,
        },
        {
            name: "end_date",
            label: "Execution Date (End)",
            type: DateField,
        },
        {
            name: "securities",
            label: "Securities",
            type: MultipleSecuritiesField,
        },
        {
            name: "linked_securities",
            label: "Linked Securities",
            type: MultipleSecuritiesField,
        },
        {
            name: "event_types",
            label: "CA Type",
            type: MultipleSelectField,
            options: Object.keys(CA_EVENT_TYPE).map((k) => ({
                id: k,
                label: CA_EVENT_TYPE[k],
            })),
        },
    ];

    const onFinishedEditingRow = async (updatedRecord) => {
        // Refresh results to show latest updated record
        queryDataTable.current.refreshResults();
        setShowEditDialog(false);
    };

    const enrichResults = useCallback(
        (results) => {
            return results.map((x) => ({
                ...x,
                index_internal_id: x.index_id
                    ? indexesById[x.index_id].internal_id
                    : "",
            }));
        },
        [indexesById],
    );

    return (
        <>
            <EditRecordDialog
                title="Edit CA"
                show={showEditDialog}
                fields={EDIT_FIELDS}
                record={corporateActions[editRowIndex]}
                updateAPIPath={"corporate_action"}
                onCancelled={() => setShowEditDialog(false)}
                onFinished={onFinishedEditingRow}
            />
            <FileImporterDialog
                title="Bulk Import Corporate Actions"
                importsText="Corporate Actions Imported"
                show={showImportDialog}
                uploadAPIPath={"import_corporate_actions"}
                importsTableColumns={COLUMNS}
                importsTableOptions={TABLE_OPTIONS}
                onCancelled={() => setShowImportDialog(false)}
                onFinished={() => setShowImportDialog(false)}
            />
            <div className="absolute right-10 top-10">
                <Tooltip title={"Bulk Import CAs"}>
                    <IconButton onClick={() => setShowImportDialog(true)}>
                        <FileUpload />
                    </IconButton>
                </Tooltip>
            </div>
            <QueryDataTable
                ref={queryDataTable}
                tableColumns={COLUMNS}
                tableOptions={TABLE_OPTIONS}
                queryFields={QUERY_FIELDS}
                queryAPIPath="corporate_actions"
                dynamicLoad={true}
                onResults={setCorporateActions}
                preprocessResults={enrichResults}
            />
        </>
    );
}
