import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable, {
    customValueRenderer,
    multiSelectColumn,
    nonFilterableColumn,
    convertToPercent,
    dateFilterColumn,
    columnWidth,
} from "../../components/DataTable";
import { getCountryName } from "../../utils/countries";
import { useState } from "react";
import { DateField, DecimalField } from "../../components/Form";
import { setDividendTaxCountries } from "../../store/securities";
import { DateTime } from "luxon";
import { EditRecordDialog } from "../../components/RecordDialog";

// Fields that the user is allowed to edit per dividend tax county
const EDIT_FIELDS = [
    {
        name: "start_date",
        label: "Start Date",
        type: DateField,
        required: true,
        // Let the user edit the time this change is done at (so it can be retroactive or in the future) -
        // defaults to today
        defaultValue: DateTime.now().toMillis(),
    },
    {
        name: "dividend_tax",
        label: "Dividend Tax Rate",
        type: DecimalField,
        required: true,
    },
];

export default function DividendTaxCountries() {
    const dispatch = useDispatch();
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [editRowIndex, setEditRowIndex] = useState(0);
    const countries = useSelector(
        (state) => state.securities.dividendTaxCountries,
    );

    const TABLE_OPTIONS = {
        viewColumns: false,
        sortOrder: {
            name: "country_code",
            direction: "asc",
        },
        onEditRow: (index) => {
            setEditRowIndex(index);
            setShowEditDialog(true);
        },
    };

    const COLUMNS = [
        multiSelectColumn(
            "country_code",
            "Country",
            customValueRenderer(getCountryName),
        ),
        nonFilterableColumn(
            "dividend_tax",
            "Dividend Tax",
            convertToPercent(3),
        ),
        dateFilterColumn("start_date", "Start Date", columnWidth("10%")),
        dateFilterColumn("end_date", "End Date", columnWidth("10%")),
    ];

    const onFinishedEditingRow = async (updatedRecord) => {
        // Refresh results to show latest updated record
        dispatch(setDividendTaxCountries([...countries, updatedRecord]));
        //queryDataTable.current.refreshResults();
        setShowEditDialog(false);
    };

    return (
        <>
            <EditRecordDialog
                title="Edit Country Dividend Tax Rate"
                show={showEditDialog}
                fields={EDIT_FIELDS}
                record={countries[editRowIndex]}
                updateAPIPath={"dividend_tax"}
                onCancelled={() => setShowEditDialog(false)}
                onFinished={onFinishedEditingRow}
                idFieldName="country_code"
            />
            <DataTable
                title={"Dividend Tax Countries"}
                data={countries}
                columns={COLUMNS}
                options={TABLE_OPTIONS}
            />
        </>
    );
}
