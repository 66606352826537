import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    managedSecurities: [],
    securitiesById: {},
    managedSources: [],
    sourcesById: {},
    dividendTaxCountries: [],
    centralBanks: [],
};

export const securitiesSlice = createSlice({
    name: "securities",
    initialState,
    reducers: {
        setManagedSecurities: (state, action) => {
            state.managedSecurities = action.payload;
            state.securitiesById = Object.fromEntries(
                action.payload.map((s) => [s.internal_id, s]),
            );
        },
        setDividendTaxCountries: (state, action) => {
            state.dividendTaxCountries = action.payload;
        },
        setCentralBanks: (state, action) => {
            state.centralBanks = action.payload;
        },
        setManagedSources: (state, action) => {
            state.managedSources = action.payload;
            state.sourcesById = Object.fromEntries(
                action.payload.map((e) => [e._id, e]),
            );
        },
    },
});

/** Returns a display name for the security (since some securities have the same name,
 * but different RIC / ISIN)
 */
export const getSecurityName = (security) => {
    return `${security.name} | ${security.ric} | ${security.isin}`;
};

export const {
    setManagedSecurities,
    setDividendTaxCountries,
    setManagedSources,
    setCentralBanks,
} = securitiesSlice.actions;
export default securitiesSlice.reducer;
