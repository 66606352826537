import * as React from "react";
import { useSelector } from "react-redux";
import {
    booleanValueRenderer,
    customValueRenderer,
    dateFilterColumn,
    dateValueRenderer,
    dropDownColumn,
    freeTextColumn,
    multiSelectColumn,
    nonFilterableColumn,
} from "../../components/DataTable";
import { getCountryName } from "../../utils/countries";
import {
    DateField,
    DecimalField,
    ExchangeField,
    MultipleCountriesField,
    MultipleExchangesField,
    MultipleSelectField,
    MultipleSourcesField,
    SourceField,
    TextField,
} from "../../components/Form";
import { getSecurityName } from "../../store/securities";
import { useRef, useState } from "react";
import QueryDataTable from "../../components/QueryDataTable";
import { EditRecordDialog } from "../../components/RecordDialog";

// Security asset type
export const ASSET_TYPE = {
    coco_bond: "CoCo Bond",
    commercial_paper: "Commercial Paper",
    common_stock: "Common Stock",
    convertible_bond: "Convertible Bond",
    makam: "Makam",
    mlp: "MLP",
    preferred_stock: "Preferred Stock",
    straight_bond: "Straight Bond",
    structured_bond: "Structured Bond",
    technology_closed_fund: "Technology Closed Fund",
    adr: "ADR",
    gdr: "GDR",
    unit: "Unit",
    cd: "CD",
    etfs: "ETFs",
};

// Security underlying asset
export const UNDERLYING_ASSET = {
    bonds: "Bonds",
    equity: "Equity",
};

// Security issue type
export const ISSUE_TYPE = {
    corporate: "Corporate",
    government: "Government",
};

// TASE Sub industry types
const TASE_SUB_INDUSTRY = {
    government: "ממשלתי",
    biotech: "ביוטכנולוגיה",
    medical_devices: "מכשור רפואי",
    pharmaceuticals: "פארמה",
    cannabis: "קנאביס",
    investments_in_life_science: "השקעות במדעי החיים",
    it_services: "שירותי מידע",
    software_and_internet: "תוכנה ואינטרנט",
    electronics_and_optics: "אלקטרוניקה ואופטיקה",
    robotics_and_3d: "רובוטיקה ותלת מימד",
    defence: "ביטחוניות",
    communications_equipment: "ציוד תקשורת",
    semiconductors: "מוליכים למחצה",
    cleantech: "קלינטק",
    renewable_energy: "אנרגיה מתחדשת",
    foodtech: "פודטק",
    tech_investments: "השקעות בהיי-טק",
    tech_funds: "קרנות היי טק",
    banks: "בנקים",
    insurance: "ביטוח",
    diversified_financials: "שירותים פיננסיים",
    non_bank_credits: "אשראי חוץ בנקאי",
    commerce: "מסחר",
    retail_stores: "רשתות שיווק",
    services: "שירותים",
    hotels_and_tourism: "מלונאות ותיירות",
    communications_and_media: "תקשורת ומדיה",
    domestic_real_estate: 'נדל"ן מניב בישראל',
    overseas_real_estate: 'נדל"ן מניב בחו"ל',
    construction: "בנייה",
    food: "מזון",
    fashion_and_clothing: "אופנה והלבשה",
    metal: "מתכת ומוצרי בניה",
    electrical: "חשמל",
    chemical_rubber_and_plastic: "כימיה, גומי ופלסטיק",
    wood_and_paper: "עץ, נייר ודפוס",
    investment_and_holdings: "השקעה ואחזקות",
    shell_companies: "חברות מעטפת",
    oil_and_gas_exploration: "חיפושי נפט וגז",
    energy: "אנרגיה",
    etfs: "קרנות סל",
    structured_bonds: 'אג"ח מובנות',
};

// TASE industry types
const TASE_INDUSTRY = {
    government: "ממשלתי",
    biomed: "ביומד",
    technology: "טכנולוגיה",
    banks: "בנקים",
    insurance: "ביטוח",
    diversified_financials: "שירותים פיננסיים",
    commerce_and_services: "מסחר ושירותים",
    real_estate_and_construction: 'נדל"ן ובנייה',
    industrials: "תעשיה",
    investment_and_holdings: "השקעה ואחזקות",
    energy_and_oil_gas_exploration: "אנרגיה וחיפושי נפט וגז",
    financial_products: "מכשירים פיננסיים",
};

// Security sector class
const SECTOR_CLASS = {
    government: "ממשלתי",
    energy: "אנרגיה",
    health_care: "בריאות",
    materials: "חומרי גלם",
    technology: "טכנולוגיה",
    real_estate: 'נדל"ן',
    financials: "פיננסים",
    consumer_staples: "צריכה בסיסית",
    consumer_discretionary: "צריכה מחזורית",
    industrials: "תעשיה",
    communication_services: "תקשורת",
    utilities: "תשתיות",
    financial_products: "מכשירים פיננסיים",
    other: "אחר",
};

// Security trading status
const TRADING_STATUS = {
    delisted: "Delisted",
    halted: "Halted",
    illiquid_list: "דלי סחירות",
    listed: "רציף",
    maintenance_list: "שימור",
};

// Security Collateral type
const COLLATERAL_TYPE = {
    first_floating: "צף ראשון",
    second_floating: "צף שני",
    first_lien: "קבוע ראשון",
    second_lien: "קבוע שני",
    third_lien: "קבוע שלישי",
};

// Security Collateral description
const COLLATERAL_DESCRIPTION = {
    listed_securities: 'ני"ע סחירים',
    unlisted_securities: "מניות של חברה פעילה",
    real_estate: 'נכסי נדל"ן',
    intangible_assets: "נכסי מטלטלין",
    government_guarantee: "ערבות מדינה",
    company_guarantee: "ערבות חברה",
    bank_account: "חשבון בנק",
    bank_deposit: "פיקדון בבנק",
    rights: "זכויות",
    excesses_project_revenue: "רווחי פרויקט",
    mixed_collaterals: "שילוב בטוחות",
};

// Bond Seniority Type
const BOND_SENIORITY_TYPE = {
    unsubordinated_debt: "חוב רגיל",
    senior_debt: "חוב בכיר",
    subordinated_debt: "חוב נחות",
    tier2: "רובד 2",
    secondary_complex: "משני מורכב",
    secondary_subordinated: "משני נחות",
    third_complex: "שלישוני מורכב",
};

// Location in capital structure type
const LOCATION_IN_CAPITAL_STRUCTURE = {
    regular_commitment: "התחייבות רגילה",
    secondary_complex_and_tier2: "הון משני מורכב והון רובד 2",
    secondary_subordinated_and_third_complex: "הון משני נחות ושלישוני מורכב",
};

// Maturity type
const MATURITY_TYPE = {
    bullet: "בלון",
    partial: "לשיעורין",
};

// Interest type
const INTEREST_TYPE = {
    fixed: "קבועה",
    frn: "משתנה",
    zero: "ללא קופון",
};

// Linkage type
const LINKAGE_TYPE = {
    cpi: "מדד",
    non_linked: "שקלי",
    usd: 'דולר ארה"ב',
    eur: "אירו",
    gbp: "לירה שטרלינג",
};

// SP IL Outlook
const SP_IL_OUTLOOK = {
    stable: "יציב",
    positive: "חיובי",
    negative: "שלילי",
};

// Moodys IL Outlook
const MOODYS_IL_OUTLOOK = {
    stable: "יציב",
    positive: "חיובי",
    negative: "שלילי",
};

// Fields that the user is allowed to edit per security
const EDIT_FIELDS = [
    {
        name: "name",
        label: "Name",
        type: TextField,
        required: true,
    },
    {
        name: "ric",
        label: "RIC",
        type: TextField,
        required: true,
    },
    {
        name: "isin",
        label: "ISIN",
        type: TextField,
        required: true,
    },
    {
        name: "symbol",
        label: "Symbol",
        type: TextField,
        required: true,
    },
    {
        name: "exchange_id",
        label: "Exchange",
        type: ExchangeField,
        required: true,
    },
    {
        name: "source_id",
        label: "Source",
        type: SourceField,
        required: true,
    },
    {
        name: "trading_units",
        label: "Trading Units",
        type: DecimalField,
        required: true,
    },
    {
        name: "disabled_from",
        label: "Disabled From",
        type: DateField,
        // So we can allow the user to clear the date (in case we want to re-enable the security)
        allowEmpty: true,
    },
];

export default function ManagedSecurities() {
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [editRowIndex, setEditRowIndex] = useState(0);
    const [managedSecurities, setManagedSecurities] = useState([]);
    const exchangesById = useSelector((state) => state.exchanges.exchangesById);
    const sourcesById = useSelector((state) => state.securities.sourcesById);
    const queryDataTable = useRef();
    const securitiesById = useSelector(
        (state) => state.securities.securitiesById,
    );

    const makeEnumField = (key, label, enumObj) => ({
        name: key,
        label: label,
        type: MultipleSelectField,
        options: Object.keys(enumObj).map((k) => ({
            id: k,
            label: enumObj[k],
        })),
    });

    const TABLE_OPTIONS = {
        sortOrder: {
            name: "name",
            direction: "asc",
        },
        onEditRow: (index) => {
            setEditRowIndex(index);
            setShowEditDialog(true);
        },
    };

    const COLUMNS = [
        freeTextColumn("name", "Name"),
        freeTextColumn("ric", "RIC"),
        freeTextColumn("isin", "ISIN"),
        freeTextColumn("symbol", "Symbol"),
        multiSelectColumn("trading_units", "Trading Units"),
        multiSelectColumn(
            "domicile_country_code",
            "Domicile Country",
            customValueRenderer(getCountryName),
        ),
        multiSelectColumn(
            "exchange_id",
            "Exchange",
            customValueRenderer((x) => exchangesById[x].name),
        ),
        dateFilterColumn("disabled_from", "Disabled From"),
        multiSelectColumn(
            "source_id",
            "Source",
            customValueRenderer((x) => sourcesById[x].name),
        ),
        multiSelectColumn("issuer_id", "Issuer ID", { display: false }),
        multiSelectColumn("issuer_name", "Issuer Name", { display: false }),
        multiSelectColumn("issuer_name_hebrew", "Issuer Name Hebrew", {
            display: false,
        }),
        multiSelectColumn("issuer_parent_id", "Issuer Parent ID", {
            display: false,
        }),
        multiSelectColumn(
            "underlying_asset",
            "Underlying Asset",
            customValueRenderer((x) => UNDERLYING_ASSET[x]),
        ),

        // Optionally displayed columns
        multiSelectColumn("currency", "Currency", { display: false }),
        freeTextColumn("security_exchange_id", "Security Exchange Id", {
            display: false,
        }),
        freeTextColumn("security_exchange_ticker", "Security Exchange Ticker", {
            display: false,
        }),
        freeTextColumn("name_hebrew", "Name Hebrew", { display: false }),
        multiSelectColumn("asset_type", "Asset Type", {
            ...customValueRenderer((x) => ASSET_TYPE[x]),
            display: false,
        }),
        multiSelectColumn("issue_type", "Issue Type", {
            ...customValueRenderer((x) => ISSUE_TYPE[x]),
            display: false,
        }),
        freeTextColumn(
            "controlling_shareholder_hebrew",
            "Controlling Shareholder Hebrew",
            { display: false },
        ),
        dropDownColumn("israeli_linkage", "Israeli Linkage", {
            ...booleanValueRenderer(),
            display: false,
        }),
        multiSelectColumn(
            "country_of_incorporation",
            "Country of Incorporation",
            { ...customValueRenderer(getCountryName), display: false },
        ),
        multiSelectColumn("country_hq", "Country HQ", {
            ...customValueRenderer(getCountryName),
            display: false,
        }),
        multiSelectColumn("country_class", "Country Class", {
            ...customValueRenderer(getCountryName),
            display: false,
        }),
        multiSelectColumn("tase_sub_industry", "TASE Sub Industry", {
            ...customValueRenderer((x) => TASE_SUB_INDUSTRY[x]),
            display: false,
        }),
        multiSelectColumn("tase_industry", "TASE Industry", {
            ...customValueRenderer((x) => TASE_INDUSTRY[x]),
            display: false,
        }),
        multiSelectColumn("sector_class", "Sector Class", {
            ...customValueRenderer((x) => SECTOR_CLASS[x]),
            display: false,
        }),
        dropDownColumn("tase_dual_listing", "TASE Dual Listing", {
            ...booleanValueRenderer(),
            display: false,
        }),
        multiSelectColumn("trading_status", "Trading Status", {
            ...customValueRenderer((x) => TRADING_STATUS[x]),
            display: false,
        }),
        dateFilterColumn("ipo_date", "IPO Date", { display: false }),
        dateFilterColumn("listing_date", "Listing Date", { display: false }),
        dateFilterColumn("delisting_date", "Delisting Date", {
            display: false,
        }),
        dateFilterColumn("bond_maturity_date", "Bond Maturity Date", {
            display: false,
        }),
        dateFilterColumn(
            "bond_early_maturity_date",
            "Bond Early Maturity Date",
            { display: false },
        ),
        multiSelectColumn("bond_maturity_year", "Bond Maturity Year", {
            ...dateValueRenderer("yyyy"),
            display: false,
        }),
        nonFilterableColumn("annual_coupon", "Annual Coupon", {
            display: false,
        }),
        nonFilterableColumn("minimum_trading_units", "Minimum Trading Units", {
            display: false,
        }),
        dateFilterColumn("credit_default_date", "Credit Default Date", {
            display: false,
        }),
        dropDownColumn(
            "convertible_deletion_option",
            "Convertible Deletion Option",
            { ...booleanValueRenderer(), display: false },
        ),
        multiSelectColumn("collateral_type", "Collateral Type", {
            ...customValueRenderer((x) => COLLATERAL_TYPE[x]),
            display: false,
        }),
        multiSelectColumn("collateral_description", "Collateral Description", {
            ...customValueRenderer((x) => COLLATERAL_DESCRIPTION[x]),
            display: false,
        }),
        freeTextColumn("underlying_security_id", "Underlying Security", {
            ...customValueRenderer((x) =>
                x ? getSecurityName(securitiesById[x]) : "",
            ),
            display: false,
        }),
        multiSelectColumn("bond_seniority_type", "Bond Seniority Type", {
            ...customValueRenderer((x) => BOND_SENIORITY_TYPE[x]),
            display: false,
        }),
        multiSelectColumn(
            "location_in_capital_structure",
            "Location In Capital Structure",
            {
                ...customValueRenderer((x) => LOCATION_IN_CAPITAL_STRUCTURE[x]),
                display: false,
            },
        ),
        multiSelectColumn("maturity_type", "Maturity Type", {
            ...customValueRenderer((x) => MATURITY_TYPE[x]),
            display: false,
        }),
        multiSelectColumn("interest_type", "Interest Type", {
            ...customValueRenderer((x) => INTEREST_TYPE[x]),
            display: false,
        }),
        multiSelectColumn("linkage_type", "Linkage Type", {
            ...customValueRenderer((x) => LINKAGE_TYPE[x]),
            display: false,
        }),
        dropDownColumn("negative_linkage_cap", "Negative Linkage Cap", {
            ...booleanValueRenderer(),
            display: false,
        }),
        multiSelectColumn("sp_il_rating", "SP IL Rating", {
            display: false,
        }),
        multiSelectColumn("moodys_il_rating", "Moodys IL Rating", {
            display: false,
        }),
        multiSelectColumn("credit_rating_tranche", "Credit Rating Tranche", {
            display: false,
        }),
        dateFilterColumn(
            "sp_il_rating_last_change",
            "SP IL Rating Last Change",
            { display: false },
        ),
        dateFilterColumn(
            "moodys_il_rating_last_change",
            "Moodys IL Rating Last Change",
            { display: false },
        ),
        multiSelectColumn("sp_il_outlook", "SP IL Outlook", {
            ...customValueRenderer((x) => SP_IL_OUTLOOK[x]),
            display: false,
        }),
        multiSelectColumn("moodys_il_outlook", "Moodys IL Outlook", {
            ...customValueRenderer((x) => MOODYS_IL_OUTLOOK[x]),
            display: false,
        }),
        freeTextColumn("equity_symbol", "Equity Symbol", { display: false }),
    ];

    const QUERY_FIELDS = [
        {
            name: "ric",
            label: "RIC",
            type: TextField,
        },
        {
            name: "isin",
            label: "ISIN",
            type: TextField,
        },
        {
            name: "symbol",
            label: "Symbol",
            type: TextField,
        },
        {
            name: "security_exchange_id",
            label: "Security Exchange ID",
            type: TextField,
        },
        {
            name: "name",
            label: "Name",
            type: TextField,
        },
        {
            name: "name_hebrew",
            label: "Name Hebrew",
            type: TextField,
        },
        {
            name: "issuer_name",
            label: "Issuer Name",
            type: TextField,
        },
        {
            name: "issuer_name_hebrew",
            label: "Issuer Name Hebrew",
            type: TextField,
        },
        {
            name: "domicile_country_codes",
            label: "Domicile Country",
            type: MultipleCountriesField,
        },
        {
            name: "exchanges",
            label: "Exchange",
            type: MultipleExchangesField,
        },

        {
            name: "sources",
            label: "Source",
            type: MultipleSourcesField,
        },
        {
            name: "disabled_from",
            label: "Disabled From",
            type: DateField,
        },
        {
            name: "bond_early_maturity_date",
            label: "Bond Early Maturity Date",
            type: DateField,
        },
        makeEnumField("sector_class", "Sector Class", SECTOR_CLASS),
        makeEnumField("underlying_asset", "Underlying Asset", UNDERLYING_ASSET),
        makeEnumField("tase_industry", "TASE Industry", TASE_INDUSTRY),
        makeEnumField(
            "tase_sub_industry",
            "TASE Sub Industry",
            TASE_SUB_INDUSTRY,
        ),
    ];

    const onFinishedEditingRow = async (updatedRecord) => {
        // Refresh results to show latest updated record
        queryDataTable.current.refreshResults();
        setShowEditDialog(false);
    };

    return (
        <>
            <EditRecordDialog
                title="Edit Managed Security"
                show={showEditDialog}
                fields={EDIT_FIELDS}
                record={managedSecurities[editRowIndex]}
                updateAPIPath={"security"}
                onCancelled={() => setShowEditDialog(false)}
                onFinished={onFinishedEditingRow}
                idFieldName="internal_id"
            />
            <QueryDataTable
                ref={queryDataTable}
                tableColumns={COLUMNS}
                tableOptions={TABLE_OPTIONS}
                queryFields={QUERY_FIELDS}
                queryAPIPath="managed_securities"
                onResults={setManagedSecurities}
                dynamicLoad={true}
            />
        </>
    );
}
