import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setUserDetails, setLoginState } from "../store/user";
import Authentication from "../modules/authentication";
import {
    PATH_LOGIN,
    PATH_NOT_VERIFIED,
    PATH_REGISTER,
    PATH_ROOT,
} from "../routes";

/**
 * Custom hook that gets triggered every time current location changes -
 * refreshes user login status, and if not logged in, will redirect to login page
 * if needed (or user not verified page).
 */
export function useCheckForLoginAndRedirect() {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        console.log("Location changed", location.pathname);

        const isLoginRequiredPage = ![
            PATH_REGISTER,
            PATH_LOGIN,
            PATH_NOT_VERIFIED,
        ].includes(location.pathname);

        const isLoginPage = [PATH_REGISTER, PATH_LOGIN].includes(
            location.pathname,
        );

        console.log(
            "checkForLoginAndRedirect",
            location.pathname,
            isLoginRequiredPage,
        );

        Authentication.getLoginStatus((status) => {
            // Update the Redux store with the latest login status and user details
            dispatch(setLoginState(status));
            Authentication.refreshUserDetails((details) => {
                dispatch(setUserDetails(details));
            });

            if (
                status === Authentication.LOGIN_STATUS_NOT_LOGGED_IN &&
                isLoginRequiredPage
            ) {
                // Not logged-in - redirect to login page
                navigate(PATH_LOGIN);
            } else if (status === Authentication.LOGIN_STATUS_UNCONFIRMED) {
                // User needs to get verified by an admin
                navigate(PATH_NOT_VERIFIED);
            } else if (status === Authentication.LOGIN_STATUS_LOGGED_IN) {
                // User logged-in
                if (isLoginPage) {
                    // User trying to open login page - redirect to main page
                    navigate(PATH_ROOT);
                }
            }
        });
    }, [location.pathname, dispatch, navigate]);
}
