import * as React from "react";
import Authentication from "../modules/authentication";
import Form from "./Form";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import DataTable, { DEFAULT_ROWS_PER_PAGE } from "./DataTable";
import Loading from "./Loading";
import { useDebounce, wait } from "../utils/hooks";
import { CloudDownload } from "@mui/icons-material";
import { downloadFile } from "../utils/file_download";

const gApiClient = Authentication.getAPIClient();

/**
 * A DataTable with a form on top of it, that queries an API and renders results
 * in the data table.
 *
 * @param queryFields (optional) Array of form fields used for the query - see `Form` component for details.
 *      If no query fields are specified, will not show a form, and will immediately perform a query to
 *      load results (with no params).
 * @param tableColumns (optional) Array of columns used to display the table - see `DataTable` component for details.
 *      If not provided, will display all columns returned from the API.
 * @param showAllColumns if true, will display all columns, even if some columns are provided in the `columns`
 *      parameter - the ones in the `columns` parameters will determine how those specific columns will be displayed -
 *      the rest of the columns will be displayed as simple text columns.
 * @param queryAPIPath The API path to call for querying (e.g. 'report') - assumes it's a GET request with params
 *      passed in URL query
 * @params hideTableColumnNames list of column names to hide / not display. Only used if `tableColumns` param is empty.
 * @param tableOptions additional/overridden table options (for MUIDataTable)
 * @param onQuerySet (optional) if set, a callback function that will be called with all values of the queries. Useful
 *      when parent component wants to be aware of the query param entered by the user.
 * @param preprocessResults (optional) if set, a callback function that processes API results before being displayed
 *      inside the data table. Useful if the parent component wants to enrich results.
 * @param dynamicLoad  (optional, default=false) if true, will load results from API dynamically, as you switch
 *      between pages, sort or free-text search through results (useful when there are a lot of results - too many
 *      to be returned in one API call). In this case, the API (from `queryAPIPath`) must support these
 *      additional GET input params:
 *              page, per_page, query (free text search), sort_key (column name), sort_direction (asc or desc).
 * @param formatCSVFilename if dynamicLoad=true, this will be the callback function that receives form data (like
 *      onQuerySet) and will return the name of the CSV file to save, if the user presses the "Download" button.
 * @param onResults (optional) if set, callback function will receive finalized results to be displayed in the table (after
 *  any processing done)
 */
export default forwardRef(
    (
        {
            queryFields = [],
            tableColumns = [],
            hideTableColumnNames = [],
            queryAPIPath,
            tableOptions = {},
            onQuerySet = null,
            preprocessResults = null,
            dynamicLoad = false,
            formatCSVFilename = null,
            onResults = null,
            showAllColumns = false,
        },
        ref,
    ) => {
        const INITIAL_DYNAMIC_PARAMS = {
            page: 1,
            perPage: tableOptions.rowsPerPage || DEFAULT_ROWS_PER_PAGE,
            sortKey: tableOptions.sortOrder
                ? tableOptions.sortOrder.name
                : null,
            sortDirection: tableOptions.sortOrder
                ? tableOptions.sortOrder.direction
                : null,
        };

        const publicRef = {
            // Methods for QueryDataTable

            /** Refreshes current query and reloads results */
            refreshResults: async () => {
                await loadResults();
            },
        };

        // pass the ref and a function that returns our object
        useImperativeHandle(ref, () => publicRef);

        // When first doing a query, entire table is hidden
        const [initialLoading, setInitialLoading] = useState(false);
        // When moving between pages, filtering, etc - an overlay appears on top of the table (used only
        // when dynamicLoad=true)
        const [loadingInsideTable, setLoadingInsideTable] = useState(false);
        const [results, setResults] = useState(null);
        const [query, setQuery] = useState(null);
        const [loadedResultsForFirstTime, setLoadedResultsForFirstTime] =
            useState(false);
        const [dynamicParams, setDynamicParams] = useState(
            INITIAL_DYNAMIC_PARAMS,
        );
        const [totalResults, setTotalResults] = useState(0);
        const [searchText, setSearchText] = useState(null);
        const debouncedSearchText = useDebounce(searchText, 500);
        const [isDownloading, setIsDownloading] = useState(false);
        const [currentTableColumns, setCurrentTableColumns] =
            useState(tableColumns);

        async function loadResults() {
            // Don't load results if query wasn't yet by the user
            if (!query) return;

            if (!loadedResultsForFirstTime) {
                setInitialLoading(true);
            } else {
                setLoadingInsideTable(true);
            }

            let finalParams;

            if (dynamicLoad) {
                // In case of dynamically loading table, we need to provide additional params to the API,
                // to indicate current page, filter text and sorting.
                finalParams = {
                    ...query,
                    page: dynamicParams.page,
                    per_page: dynamicParams.perPage,
                };
                if (debouncedSearchText)
                    finalParams.query = debouncedSearchText;
                if (dynamicParams.sortKey)
                    finalParams.sort_key = dynamicParams.sortKey;
                if (dynamicParams.sortDirection)
                    finalParams.sort_direction = dynamicParams.sortDirection;
            } else {
                finalParams = query;
            }

            const rawResponse = await gApiClient.callApi(`${queryAPIPath}`, {
                method: "GET",
                params: finalParams,
            });

            let response = rawResponse.data;
            let results = dynamicLoad ? response.results : response;

            if (preprocessResults) {
                // Do additional preprocessing of the results
                results = preprocessResults(results);
            }

            setTotalResults(response.total_results);
            setResults(results);

            if (onResults) {
                onResults(results);
            }

            if (!loadedResultsForFirstTime) {
                setInitialLoading(false);
            } else {
                setLoadingInsideTable(false);
            }

            setLoadedResultsForFirstTime(true);
        }

        useEffect(() => {
            // Reset page in case of a new free text query
            setDynamicParams({ ...dynamicParams, page: 1 });
        }, [debouncedSearchText]);

        useEffect(() => {
            loadResults();
        }, [
            query,
            dynamicParams,
            debouncedSearchText,
            dynamicLoad,
            preprocessResults,
            queryAPIPath,
        ]);

        const onSubmit = async (values) => {
            if (onQuerySet) onQuerySet(values);

            // Reset all previous dynamic params
            if (dynamicLoad) {
                setLoadedResultsForFirstTime(false);
                setDynamicParams(INITIAL_DYNAMIC_PARAMS);
                setSearchText(null);
            }

            setTotalResults(0);
            setResults(null);

            setQuery(values);
        };

        const customIcons = [
            {
                title: isDownloading ? "Downloading..." : "Download CSV",
                icon: isDownloading ? <Loading size={20} /> : <CloudDownload />,
                onClick: async () => {
                    if (isDownloading) return;

                    setIsDownloading(true);

                    // In case of dynamically loading table, we need to provide additional params to the API,
                    // to indicate filter text and sorting.
                    const finalParams = { ...query };
                    if (debouncedSearchText)
                        finalParams.query = debouncedSearchText;
                    if (dynamicParams.sortKey)
                        finalParams.sort_key = dynamicParams.sortKey;
                    if (dynamicParams.sortDirection)
                        finalParams.sort_direction =
                            dynamicParams.sortDirection;

                    // Call the API to start generating the CSV in the background
                    let response = await gApiClient.callApi(`${queryAPIPath}`, {
                        method: "POST",
                        body: finalParams,
                    });

                    const backgroundFunctionId =
                        response.data.background_function_id;

                    let backgroundFunction = null;

                    do {
                        response = await gApiClient.callApi(
                            "background_function",
                            {
                                method: "GET",
                                params: {
                                    background_function_id:
                                        backgroundFunctionId,
                                },
                            },
                        );
                        backgroundFunction = response.data;

                        await wait(500);
                    } while (
                        !backgroundFunction ||
                        backgroundFunction.status !== "finished"
                    );

                    await downloadFile(
                        backgroundFunction.results_url,
                        formatCSVFilename
                            ? formatCSVFilename(query)
                            : `${queryAPIPath}.csv`,
                    );

                    setIsDownloading(false);
                },
            },
        ];

        const finalTableOptions = { ...tableOptions };

        if (dynamicLoad) {
            finalTableOptions.download = false;
            finalTableOptions.customIcons = [
                ...customIcons,
                ...(tableOptions.customIcons ? tableOptions.customIcons : []),
            ];
            finalTableOptions.onTableChange = (action, tableState) => {
                // Only in case the user does one of these actions, we'll need to re-fetch data from the server:
                // Move between pages, change number of rows per page, free text search (top level filter), sort columns
                if (
                    ![
                        "changePage",
                        "sort",
                        "search",
                        "changeRowsPerPage",
                    ].includes(action)
                ) {
                    return;
                }

                const newDynamicParams = {};

                if (action === "search") {
                    // Reset page when changing query
                    setSearchText(tableState.searchText);
                    return;
                } else if (action === "changePage") {
                    newDynamicParams.page = tableState.page + 1;
                } else if (action === "changeRowsPerPage") {
                    newDynamicParams.perPage = tableState.rowsPerPage;
                } else if (action === "sort") {
                    newDynamicParams.sortKey = tableState.sortOrder.name;
                    newDynamicParams.sortDirection =
                        tableState.sortOrder.direction;
                }

                setDynamicParams({ ...dynamicParams, ...newDynamicParams });
            };

            finalTableOptions.searchText = dynamicParams.searchText;
            finalTableOptions.count = totalResults;
            finalTableOptions.page = dynamicParams.page - 1;
            finalTableOptions.rowsPerPage = dynamicParams.perPage;
            finalTableOptions.clientSideFiltering = true;
            finalTableOptions.serverSide = true;
            finalTableOptions.sortOrder = {
                name: dynamicParams.sortKey,
                direction: dynamicParams.sortDirection,
            };

            // When user changes a column's visibility - remember this so it won't reset
            finalTableOptions.onViewColumnsChange = (changedColumn, action) => {
                setCurrentTableColumns((cols) =>
                    cols.map((column) => {
                        if (column.name === changedColumn) {
                            return {
                                ...column,
                                options: {
                                    ...column.options,
                                    display: action === "add",
                                },
                            };
                        } else {
                            return column;
                        }
                    }),
                );
            };
        }

        useEffect(() => {
            if (!queryFields || queryFields.length === 0) {
                // No query form - immediately load results on init
                onSubmit({});
            }
        }, []);

        return (
            <div className="p-5 flex flex-col items-start h-full">
                {queryFields && queryFields.length > 0 && (
                    <Form
                        fields={queryFields}
                        onSubmit={onSubmit}
                        submitButtonText="Query"
                    />
                )}
                <div className="mt-10 w-full flex flex-col items-center grow overflow-x-hidden relative">
                    {initialLoading ? (
                        <Loading />
                    ) : results !== null ? (
                        results.length === 0 && !dynamicLoad ? (
                            <div className="text-xl font-bold">No results</div>
                        ) : (
                            <>
                                <DataTable
                                    title={"Results"}
                                    data={results}
                                    columns={currentTableColumns}
                                    hideColumnNames={hideTableColumnNames}
                                    options={finalTableOptions}
                                    showAllColumns={showAllColumns}
                                />
                                {loadingInsideTable && (
                                    <div className="absolute left-0 top-0 right-0 bottom-0 bg-gray-500 bg-opacity-50 z-[999999] flex items-center justify-center">
                                        <Loading />
                                    </div>
                                )}
                            </>
                        )
                    ) : (
                        loadingInsideTable && (
                            <div className="absolute left-0 top-0 right-0 bottom-0 bg-gray-500 bg-opacity-50 z-[999999] flex items-center justify-center">
                                <Loading />
                            </div>
                        )
                    )}
                </div>
            </div>
        );
    },
);
