import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    managedIndexes: [],
    indexesById: {},
    currentProcesses: [],
    refreshCurrentProcesses: null,
};

export const indexesSlice = createSlice({
    name: "indexes",
    initialState,
    reducers: {
        setManagedIndexes: (state, action) => {
            state.managedIndexes = action.payload;
            state.indexesById = Object.fromEntries(
                action.payload.map((i) => [i._id, i]),
            );
        },
        setCurrentProcesses: (state, action) => {
            state.currentProcesses = action.payload;
        },
        refreshCurrentProcesses: (state) => {
            // Refreshes a variable that triggers a function in AppBar - it causes
            // it to call an API to get the latest processes state
            state.refreshCurrentProcesses = Math.random();
        },
    },
});

export const {
    setManagedIndexes,
    setCurrentProcesses,
    refreshCurrentProcesses,
} = indexesSlice.actions;
export default indexesSlice.reducer;
