import * as React from "react";
import { CircularProgress } from "@mui/material";

export default function Loading({ size = 100 }) {
    return (
        <CircularProgress
            className="place-self-center"
            color="secondary"
            size={size}
        />
    );
}
