import * as React from "react";
import { DateTime } from "luxon";
import { MobileDatePicker } from "@mui/x-date-pickers";
import MUIDataTable from "mui-datatables";
import {
    Box,
    FormGroup,
    FormLabel,
    IconButton,
    TextField,
    Tooltip,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useMemo, useState } from "react";

export const DEFAULT_ROWS_PER_PAGE = 25;

// Base/Default table options
const TABLE_OPTIONS = {
    responsive: "standard",
    tableBodyMaxHeight: "calc(100% - 150px)",
    download: true,
    downloadOptions: {
        filename: "download.csv",
        separator: ",",
        filterOptions: {
            useDisplayedColumnsOnly: true,
            useDisplayedRowsOnly: true,
        },
    },
    print: false,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    rowsPerPageOptions: [25, 50, 100],
    selectableRows: "none",
    resizableColumns: true,
    searchAlwaysOpen: true,
    enableNestedDataAccess: ".",
    showRowNumbers: true,
    textLabels: {
        body: {
            noMatch: "No results",
        },
    },
};

// Default date display format
export const DISPLAY_DATE_FORMAT = "dd/MM/yyyy";

/** Custom toolbar - used when we want additional icons in the data table toolbar */
function CustomToolbar({ icons }) {
    return (
        <React.Fragment>
            {icons.map((icon) => (
                <Tooltip key={icon.title} title={icon.title}>
                    <IconButton disabled={icon.disabled} onClick={icon.onClick}>
                        {icon.icon}
                    </IconButton>
                </Tooltip>
            ))}
        </React.Fragment>
    );
}

/**
 * Base data table used by various pages (managed indexes, managed exchanges, etc.) - contains default
 * options for all. Based on MUIDataTable library.
 *
 * @param data data to display
 * @param title title of the table
 * @param options additional/overridden table options (for MUIDataTable)
 * @param columns additional/overridden columns options (for MUIDataTable) - if not provided, will display
 *      all possible columns (assuming they're all simple textual columns).
 * @param showAllColumns if true, will display all columns, even if some columns are provided in the `columns`
 *      parameter - the ones in the `columns` parameters will determine how those specific columns will be displayed -
 *      the rest of the columns will be displayed as simple text columns.
 * @params hideColumnNames list of column names to hide / not display. Only used if `columns` param is empty.
 */
export default function DataTable({
    title,
    data,
    options = {},
    columns = [],
    showAllColumns = false,
    hideColumnNames = [],
}) {
    const [currentSorting, setCurrentSorting] = useState();

    const finalTableOptions = useMemo(() => {
        let tableOptions = { ...TABLE_OPTIONS, ...options };
        tableOptions.downloadOptions.filename = `${title}.csv`;
        tableOptions.onDownload = (
            buildHead,
            buildBody,
            columns,
            dataToDownload,
        ) => {
            // For any columns with the `downloadOriginal` column option - instead of showing the display value in the
            // downloaded CSV, use the original value
            columns.forEach((column, index) => {
                if (!column.downloadOriginal) return;
                dataToDownload = dataToDownload.map((x, subIndex) => {
                    const originalIndex = x.data[0] - 1;
                    x.data[index] = data[originalIndex][column.name];
                    return x;
                });
            });

            // Add UTF-8 BOM header, so text in Hebrew won't come out as gibberish
            return "\uFEFF" + buildHead(columns) + buildBody(dataToDownload);
        };

        if (tableOptions.customIcons) {
            // Render an additional custom toolbar with custom icons
            tableOptions = {
                ...tableOptions,
                customToolbar: () => (
                    <CustomToolbar icons={tableOptions.customIcons} />
                ),
            };
        }

        if (!options.onColumnSortChange) {
            tableOptions.onColumnSortChange = (changedColumn, direction) => {
                // If user dynamically changes the column sorting, remember current selected sorting
                setCurrentSorting({
                    name: changedColumn,
                    direction,
                });
            };
        }

        if (currentSorting) {
            // If user dynamically changes the column sorting, remember current selected sorting
            tableOptions.sortOrder = currentSorting;
        }

        return tableOptions;
    }, [options, title, currentSorting]);

    const finalColumns = useMemo(() => {
        let newColumns = columns;
        if (columns.length === 0 || showAllColumns) {
            // No columns given - display all possible columns according to current data, while assuming each
            // column is a simple text column.
            let allColumnNames = new Set(
                [].concat.apply(
                    [],
                    data.map((x) => Object.keys(x)),
                ),
            );
            // Filter out any column names according to hideColumnNames
            allColumnNames = [...allColumnNames].filter(
                (c) => !hideColumnNames.includes(c),
            );

            if (columns.length > 0) {
                // Replace some of the given columns with the user-provided display of them (according to the
                // `columns` parameter).
                newColumns = [];
                const existingColumnsByName = columns.reduce((acc, cur) => {
                    acc[cur.name] = cur;
                    return acc;
                }, {});

                for (const columnName of allColumnNames) {
                    if (existingColumnsByName[columnName]) {
                        // User has provided a specific way to display this column
                        newColumns.push(existingColumnsByName[columnName]);
                    } else {
                        // User hasn't provided a display for this column - display as simple text column
                        newColumns.push(freeTextColumn(columnName, columnName));
                    }
                }
            } else {
                // Display all columns as simple text columns
                newColumns = allColumnNames.map((c) => freeTextColumn(c, c));
            }
        }

        if (finalTableOptions.onEditRow) {
            // Add a column for editing rows (will call custom callback function)
            newColumns = [
                nonFilterableColumn("Actions", "", {
                    ...columnWidth("10px"),
                    ...customValueRenderer((...[, index]) => (
                        <Tooltip title="Edit">
                            <IconButton
                                onClick={() =>
                                    finalTableOptions.onEditRow(index)
                                }
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    )),
                    sort: false,
                    empty: true,
                    viewColumns: false,
                    download: false,
                }),
                ...newColumns,
            ];
        }
        if (finalTableOptions.showRowNumbers) {
            // Add a first column to show row numbers
            newColumns = [
                nonFilterableColumn("", "", {
                    ...columnWidth("10px"),
                    ...customValueRenderer(
                        (...[, , tableMeta]) =>
                            // Row index is 1-based, not zero based
                            tableMeta.rowIndex +
                            1 +
                            // Also account for cases where the table is dynamically loaded, and then we don't
                            // have all the results in memory
                            tableMeta.tableState.page *
                                tableMeta.tableState.rowsPerPage,
                    ),
                    sort: false,
                    viewColumns: false,
                    download: false,
                }),
                ...newColumns,
            ];
        }

        newColumns = [
            ...newColumns,
            {
                name: "originalData",
                label: "OriginalData",
                options: {
                    display: false,
                    filter: false,
                    sort: false,
                    download: true,
                    customBodyRender: (value, tableMeta) => {
                        const tableData = tableMeta.tableData;
                        const index = tableMeta.rowIndex;
                        const rowData = tableData[index];
                        return rowData;
                    },
                },
            },
        ];

        return newColumns;
    }, [columns, data, finalTableOptions, hideColumnNames, showAllColumns]);

    return (
        <>
            <div className="h-full w-full">
                <MUIDataTable
                    className="h-full"
                    title={title}
                    data={data}
                    columns={finalColumns}
                    options={finalTableOptions}
                />
            </div>
        </>
    );
}

/**
 * Returns a column definition (that will be fed into the mui-datatables' columns array) -
 * see here: https://github.com/gregnb/mui-datatables#column-options
 * @param name name of the field (as it's returned from the API output)
 * @param label display label
 * @param filterType filter type ('checkbox', 'dropdown', 'multiselect', 'textField', 'custom')
 * @param additionalOptions (optional) additional options for the column (inside the 'options' key)
 * @returns object that represents a single column configuration
 */
export function column(name, label, filterType, additionalOptions = {}) {
    return {
        name,
        label,
        options: {
            filterType,
            // This shows a prefix for the filter chip (e.g. "label: labelValue"), so the user will know what
            // he's filtering by
            customFilterListOptions: {
                render: (x) => `${label}: ${x}`,
            },
            ...additionalOptions,
        },
    };
}

/** Returns a column that you can filter by free text */
export function freeTextColumn(name, label, additionalOptions = {}) {
    return column(name, label, "textField", additionalOptions);
}

/** Returns a column that thousands Separator and Round By "maximumFractionDigits"*/
export function thousandsSeparatorRound(maximumFractionDigits) {
    return customValueRenderer((x) =>
        x !== undefined
            ? `${(x * 1).toLocaleString(undefined, {
                  maximumFractionDigits: maximumFractionDigits,
              })}`
            : "",
    );
}

/** Returns a column that Round "toFix" digits after decimal point*/
export function roundNumbers(toFix) {
    return customValueRenderer((x) =>
        x !== undefined ? `${parseFloat((x * 1).toFixed(toFix))}` : "",
    );
}

/** Returns a column that convert to percent and Round "toFix" digits after decimal point*/
export function convertToPercent(toFix) {
    if (toFix === undefined) {
        return customValueRenderer((x) =>
            x !== undefined ? `${parseFloat(x * 100)}%` : "",
        );
    } else {
        return customValueRenderer((x) =>
            x !== undefined ? `${parseFloat((x * 100).toFixed(toFix))}%` : "",
        );
    }
}

/** Returns a column that you can filter by multi-selection checkboxes */
export function multiSelectColumn(name, label, additionalOptions = {}) {
    return column(name, label, "multiselect", additionalOptions);
}

/** Returns a column that you can filter by dropdown single selection */
export function dropDownColumn(name, label, additionalOptions = {}) {
    return column(name, label, "dropdown", additionalOptions);
}

/** Returns a column that cannot be filtered by */
export function nonFilterableColumn(name, label, additionalOptions = {}) {
    return column(name, label, "dropdown", {
        filter: false,
        ...additionalOptions,
    });
}

/** Returns a column that you can filter by a custom date range selection dialog */
export function dateFilterColumn(name, label, additionalOptions = {}) {
    return column(name, label, "custom", {
        customFilterListOptions: {
            // Special formatting for date range
            render: (filters) => {
                let dateRange;
                if (!filters[1]) {
                    dateRange = `From ${filters[0].toFormat(
                        DISPLAY_DATE_FORMAT,
                    )}`;
                } else if (!filters[0]) {
                    dateRange = `Up to ${filters[1].toFormat(
                        DISPLAY_DATE_FORMAT,
                    )}`;
                } else {
                    dateRange = `${filters[0].toFormat(
                        DISPLAY_DATE_FORMAT,
                    )} -> ${filters[1].toFormat(DISPLAY_DATE_FORMAT)}`;
                }

                return `${label}: ${dateRange}`;
            },
        },
        filterOptions: {
            names: [],
            logic: (value, filters, row) => {
                // Filter value according to specific date range
                const valueDate = DateTime.fromFormat(
                    value,
                    DISPLAY_DATE_FORMAT,
                );

                let v = false; // By default, no filtering (no date range specified)

                if (filters.length === 2) {
                    // In case of empty values
                    if (value === "") return true;

                    if (!filters[1]) {
                        // Only beginning date range
                        v = valueDate < filters[0];
                    } else if (!filters[0]) {
                        // Only end date range
                        v = valueDate > filters[1];
                    } else {
                        // Both start and end date range
                        v = valueDate > filters[1] || valueDate < filters[0];
                    }
                }
                return v;
            },
            display: (filterList, onChange, index, column) => (
                <div>
                    <FormLabel>{label}</FormLabel>
                    <FormGroup row>
                        <MobileDatePicker
                            value={filterList[index][0] || null}
                            onChange={(newValue) => {
                                if (filterList[index].length === 0) {
                                    filterList[index] = Array(2);
                                }
                                filterList[index][0] = newValue;
                                onChange(filterList[index], index, column);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} className="w-[150px]" />
                            )}
                            ampm={false}
                            inputFormat={DISPLAY_DATE_FORMAT}
                        />
                        <Box className="ml-2 mr-2 place-self-center"> to </Box>
                        <MobileDatePicker
                            value={filterList[index][1] || null}
                            onChange={(newValue) => {
                                if (filterList[index].length === 0) {
                                    filterList[index] = Array(2);
                                }
                                filterList[index][1] = newValue;
                                onChange(filterList[index], index, column);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} className="w-[150px]" />
                            )}
                            ampm={false}
                            inputFormat={DISPLAY_DATE_FORMAT}
                        />
                    </FormGroup>
                </div>
            ),
        },
        // This type of column renders its values as dates by default
        ...dateValueRenderer(
            additionalOptions.dateFormat || DISPLAY_DATE_FORMAT,
        ),
        ...additionalOptions,
    });
}

/** Returns an object that will be fed into a column's 'options' key - calls
 * a custom callback function to render a specific value differently.
 *
 * @param cb callback function receiving input value + index + table meta, returning transformed value
 * @param sortByOriginalValue (optional, default=false) if true, will use original column value when sorting,
 *      instead of sorting by custom rendered value.
 */
export function customValueRenderer(cb, sortByOriginalValue = false) {
    let options = {
        customBodyRender: (value, tableMeta) => {
            const index = tableMeta.currentTableData[tableMeta.rowIndex].index;
            return cb(value, index, tableMeta);
        },
    };

    if (!sortByOriginalValue) {
        // Add a custom sort to sort by the display value (returned by the callback function) - otherwise,
        // default sorting of this column will be by the original value of the column, and not the custom
        // rendered one.
        options = {
            ...options,
            ...customSort((a, b) => {
                const valA = cb(a),
                    valB = cb(b);

                if (typeof valA === "string") {
                    // String comparison
                    return valA.localeCompare(valB);
                } else {
                    // All other types
                    return valA - valB;
                }
            }),
        };
    }

    return options;
}

/** Returns an object that will be fed into a column's 'options' key - renders
 * an epoch time in milliseconds into a date value.
 *
 * @param format (optional, default=dd/MM/yyyy) how to format the date
 */
export function dateValueRenderer(format = DISPLAY_DATE_FORMAT) {
    return customValueRenderer(
        (x) => (x ? DateTime.fromMillis(x).toFormat(format) : ""),
        // For date-value rendering, we want to sort by the original column value, not the rendered string
        true,
    );
}

/** Returns an object that will be fed into a column's 'options' key - renders
 * a boolean value into Yes/No
 */
export function booleanValueRenderer() {
    return customValueRenderer((x) => (x ? "Yes" : "No"));
}

/** Returns an object that will be fed into a column's 'options' key - sets
 * the column's custom style (CSS)
 *
 * @param style either CSS style object, CSS class name or a callback function that receives cell value as param,
 *      and returns an object of { style: { style object }, className: "class name" }
 */
export function columnStyle(style) {
    let retVal,
        isFunction = false;
    if (typeof style === "object") {
        // It's a stylesheet object
        retVal = {
            style: style,
        };
    } else if (typeof style === "string") {
        // It's a string class name
        retVal = {
            className: style,
        };
    } else if (typeof style === "function") {
        // It's a callback function that gets passed the cell value as param
        isFunction = true;
    }

    return {
        setCellProps: (value) => {
            return isFunction ? style(value) : retVal;
        },
    };
}

/** Returns an object that will be fed into a column's 'options' key - sets
 * the column's width
 *
 * @param width width of the column (e.g. '30%')
 */
export function columnWidth(width) {
    return columnStyle({ width });
}

/** Returns an object that will be fed into a column's 'options' key - sets
 * a custom sorting function
 *
 * @param cb the sorting function (receives two params with column values, returns sorting key - -1/0/1). Notice
 *  that the function shouldn't care about ascending/descending order - that is taken care of by this function.
 */
export function customSort(cb) {
    return {
        sortCompare: (order) => {
            return ({ data: a }, { data: b }) => {
                return cb(a, b) * (order === "asc" ? 1 : -1);
            };
        },
    };
}
