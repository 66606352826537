import * as React from "react";
import { Button, Dialog } from "@mui/material";
import Form, {
    BooleanField,
    DateField,
    SelectField,
} from "../../components/Form";
import Authentication from "../../modules/authentication";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sleep } from "../../utils/time";
import { refreshCurrentProcesses } from "../../store/indexes";
import { LoadingButton } from "@mui/lab";
import { DateTime } from "luxon";
import ErrorLabel from "../../components/Error";
import { API_DATE_FORMAT } from "../../hooks/useLoadAllData";

const gApiClient = Authentication.getAPIClient();

/**
 * Dialog that allows the user to recalculate all indexes from a specific date
 */
export default function RecalculateIndexes({ show, onCancelled, onFinished }) {
    const dispatch = useDispatch();
    const [finished, setFinished] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [taseEarlyError, setTaseEarlyError] = useState();
    const [lateError, setLateError] = useState();
    const [indicesCAError, setIndicesCAError] = useState();
    const indexCalcProcess = useSelector(
        (state) => state.indexes.indexCalculationProcess,
    );

    const calcProcessRunning =
        indexCalcProcess && indexCalcProcess.status === "started";

    const RECALCULATE_INDEXES_FIELDS = [
        {
            name: "start_date",
            label: "Start Date",
            type: DateField,
            required: true,
            defaultValue: DateTime.now().minus({ days: 1 }).toMillis(),
        },
    ];
    const INDICES_CA_REPORT_FIELDS = [
        {
            name: "start_date",
            label: "Start Date",
            type: SelectField,
            options: [
                { id: "today", label: "Today" },
                { id: "previous", label: "Previous" },
            ],
            required: true,
        },
        {
            name: "include_rebalancing",
            label: "Include Rebalancing?",
            type: BooleanField,
            required: true,
            defaultValue: false,
        },
    ];

    useEffect(() => {
        if (show) {
            // Reset dialog
            setFinished(false);
        }
    }, [show]);

    const onSubmit = async (values) => {
        await gApiClient.callApi("recalculate_all_indexes", {
            method: "POST",
            body: values,
        });

        // Wait a little before we refresh the index calc process appearing in the app bar
        await sleep(3000);
        dispatch(refreshCurrentProcesses());

        setFinished(true);
    };

    const generateIndicesCAReport = async (values) => {
        setSubmitting(true);

        // start_date is today if the user selected "today" in the form, otherwise it's yesterday
        const startDate =
            values.start_date === "today"
                ? DateTime.now()
                : DateTime.now().minus({ days: 1 });

        try {
            await gApiClient.callApi("generate_daily_cas_report", {
                method: "POST",
                body: {
                    include_rebalancing: values.include_rebalancing,
                    start_date: startDate.toFormat(API_DATE_FORMAT),
                },
            });
            setSubmitting(false);
            onFinished();
        } catch (exception) {
            setSubmitting(false);
            console.error(exception);
            const message =
                exception.response?.data?.Message || exception.message;
            setIndicesCAError(message);
        }
    };

    const triggerLateIndexCalculation = async () => {
        setSubmitting(true);
        try {
            await gApiClient.callApi("trigger_late_index_calculation", {
                method: "POST",
            });

            setSubmitting(false);
            onFinished();
        } catch (exception) {
            setSubmitting(false);
            console.error(exception);
            const message =
                exception.response?.data?.Message || exception.message;
            setLateError(message);
        }
    };

    const triggerTASEEarlyIndexCalculation = async () => {
        setSubmitting(true);
        try {
            await gApiClient.callApi("trigger_tase_early_index_calculation", {
                method: "POST",
            });

            setSubmitting(false);
            onFinished();
        } catch (exception) {
            setSubmitting(false);
            console.error(exception);
            const message =
                exception.response?.data?.Message || exception.message;
            setTaseEarlyError(message);
        }
    };

    return (
        <Dialog
            open={show}
            onClose={() => {
                finished ? onFinished() : onCancelled();
            }}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <div className="relative">
                <div className="p-10 flex flex-col items-center">
                    <div className="p-10 flex flex-col items-center">
                        <div className="text-center mb-10 text-3xl">
                            Recalculate All Indexes
                        </div>
                        {calcProcessRunning && !finished ? (
                            <div className="flex flex-col items-center">
                                <div className="mb-4">
                                    Index calculation process is currently
                                    running - cannot do a recalculation.
                                </div>
                                <Button
                                    variant="contained"
                                    onClick={onFinished}
                                >
                                    Close
                                </Button>
                            </div>
                        ) : finished ? (
                            <div className="flex flex-col items-center">
                                <div className="mb-4">
                                    Sent request - recalculation will be done in
                                    the background and will take several
                                    minutes. You will receive a separate index
                                    report email for each day calculated.
                                </div>
                                <Button
                                    variant="contained"
                                    onClick={onFinished}
                                >
                                    Close
                                </Button>
                            </div>
                        ) : (
                            <Form
                                fields={RECALCULATE_INDEXES_FIELDS}
                                onSubmit={onSubmit}
                                submitButtonText={"Recalculate Indexes"}
                                additionalButtons={[
                                    { text: "Cancel", onPress: onCancelled },
                                ]}
                                hideResetButton={true}
                                className={"flex flex-col items-center"}
                            />
                        )}
                    </div>

                    <hr className="w-full h-0.5 bg-gray-200" />
                    <div className="text-center mb-10 mt-10 text-3xl">
                        Generate Indices CA Report
                    </div>
                    <div className="pb-10 flex flex-col items-center">
                        <Form
                            fields={INDICES_CA_REPORT_FIELDS}
                            onSubmit={generateIndicesCAReport}
                            submitButtonText={"Generate Indices CA Report"}
                            hideResetButton={true}
                            className={"flex flex-col items-center"}
                        />
                        {indicesCAError && (
                            <ErrorLabel>{indicesCAError}</ErrorLabel>
                        )}
                    </div>

                    <hr className="w-full h-0.5 bg-gray-200" />
                    <div className="p-10 flex flex-col items-center">
                        <div className="text-center text-3xl">
                            Trigger Late Index Calculation
                        </div>
                        <div className="mb-5">(for yesterday's date)</div>
                        <LoadingButton
                            variant="contained"
                            onClick={triggerLateIndexCalculation}
                            loading={submitting}
                        >
                            Trigger late index calculation
                        </LoadingButton>
                        {lateError && <ErrorLabel>{lateError}</ErrorLabel>}
                    </div>

                    <hr className="w-full h-0.5 bg-gray-200" />
                    <div className="p-10 flex flex-col items-center">
                        <div className="text-center text-3xl">
                            Trigger TASE Early Index Calculation
                        </div>
                        <div className="mb-5">(for today's date)</div>
                        <LoadingButton
                            variant="contained"
                            onClick={triggerTASEEarlyIndexCalculation}
                            loading={submitting}
                        >
                            Trigger TASE early index calculation
                        </LoadingButton>
                        {taseEarlyError && (
                            <ErrorLabel>{taseEarlyError}</ErrorLabel>
                        )}
                    </div>
                </div>
                {submitting && (
                    <div className="absolute inset-0 bg-gray-500 opacity-50"></div>
                )}
            </div>
        </Dialog>
    );
}
