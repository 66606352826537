import * as React from "react";
import { useSelector } from "react-redux";
import {
    booleanValueRenderer,
    columnWidth,
    customValueRenderer,
    dateFilterColumn,
    dropDownColumn,
    freeTextColumn,
    multiSelectColumn,
    nonFilterableColumn,
    thousandsSeparatorRound,
    roundNumbers,
} from "../../components/DataTable";
import { Add, FileUpload } from "@mui/icons-material";
import FileImporterDialog from "../../components/FileImporterDialog";
import { useCallback, useRef, useState } from "react";
import { getSecurityName } from "../../store/securities";
import QueryDataTable from "../../components/QueryDataTable";
import {
    BooleanField,
    DateField,
    DecimalField,
    IndexField,
    IntField,
    MultipleIndexesField,
    MultipleSecuritiesField,
    MultipleSelectField,
    SecurityField,
    SelectField,
} from "../../components/Form";
import { IconButton, Tooltip } from "@mui/material";
import {
    AddRecordDialog,
    EditRecordDialog,
} from "../../components/RecordDialog";

const INDEX_ACTION_TYPE = {
    add_security_to_index: "Add Security to Index",
    remove_security_from_index: "Remove Security from Index",
    change_security_factor: "Change Security Factor",
    change_security_shares: "Change Security Shares",
};

const ADD_EDIT_FIELDS = [
    {
        name: "action_type",
        label: "Action Type",
        type: SelectField,
        options: Object.keys(INDEX_ACTION_TYPE).map((k) => ({
            id: k,
            label: INDEX_ACTION_TYPE[k],
        })),
        required: true,
    },
    {
        name: "index_id",
        label: "Index",
        type: IndexField,
        required: true,
    },
    {
        name: "security_id",
        label: "Security",
        type: SecurityField,
        required: true,
    },
    {
        name: "effective_date",
        label: "Effective Date",
        type: DateField,
        required: true,
    },
    {
        name: "factor",
        label: "Factor",
        type: DecimalField,
        required: false,
    },
    {
        name: "number_of_shares",
        label: "Number of Shares",
        type: IntField,
        required: false,
    },
    {
        name: "rebalancing",
        label: "Rebalancing?",
        type: BooleanField,
        required: false,
        defaultValue: false,
    },
];

export default function IndexActions() {
    const [showImportDialog, setShowImportDialog] = useState(false);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [indexActions, setIndexActions] = useState([]);
    const [editRowIndex, setEditRowIndex] = useState(0);
    const securitiesById = useSelector(
        (state) => state.securities.securitiesById,
    );
    const indexesById = useSelector((state) => state.indexes.indexesById);
    const queryDataTable = useRef();

    const TABLE_OPTIONS = {
        sortOrder: {
            name: "effective_date",
            direction: "asc",
        },
        customIcons: [
            {
                title: "Add New Index Action",
                icon: <Add />,
                onClick: () => {
                    setShowAddDialog(true);
                },
            },
        ],
        onEditRow: (index) => {
            setEditRowIndex(index);
            setShowEditDialog(true);
        },
    };

    const QUERY_FIELDS = [
        {
            name: "start_date",
            label: "Effective Date (Start)",
            type: DateField,
        },
        {
            name: "end_date",
            label: "Effective Date (End)",
            type: DateField,
        },
        {
            name: "indexes",
            label: "Indexes",
            type: MultipleIndexesField,
        },
        {
            name: "securities",
            label: "Securities",
            type: MultipleSecuritiesField,
        },
        {
            name: "action_types",
            label: "Action Type",
            type: MultipleSelectField,
            options: Object.keys(INDEX_ACTION_TYPE).map((k) => ({
                id: k,
                label: INDEX_ACTION_TYPE[k],
            })),
        },
    ];

    const COLUMNS = [
        freeTextColumn("index.name", "Index"),
        multiSelectColumn("index.internal_id", "Index ID"),
        multiSelectColumn(
            "action_type",
            "Action",
            customValueRenderer((x) => INDEX_ACTION_TYPE[x]),
        ),
        dateFilterColumn("effective_date", "Effective Date"),
        nonFilterableColumn(
            "number_of_shares",
            "Capital",
            thousandsSeparatorRound(0),
        ),
        nonFilterableColumn("factor", "Factor", roundNumbers(8)),
        freeTextColumn(
            "security_id",
            "Security",
            customValueRenderer((x) => getSecurityName(securitiesById[x])),
        ),
        dropDownColumn("applied", "Applied", {
            ...booleanValueRenderer(),
            ...columnWidth("50px"),
        }),
        dropDownColumn("is_disabled", "Disabled", {
            ...booleanValueRenderer(),
            ...columnWidth("50px"),
        }),
        dropDownColumn("rebalancing", "Rebalancing", {
            ...booleanValueRenderer(),
            ...columnWidth("50px"),
        }),
    ];

    const enrichResults = useCallback(
        (results) => {
            return results.map((x) => ({
                ...x,
                index: indexesById[x.index_id],
            }));
        },
        [indexesById],
    );

    const onFinishedAddingRow = async (newRecord) => {
        // Refresh results to show new record
        queryDataTable.current.refreshResults();
        setShowAddDialog(false);
    };

    const onFinishedEditingRow = async (updatedRecord) => {
        // Refresh results to show latest updated record
        queryDataTable.current.refreshResults();
        setShowEditDialog(false);
    };

    return (
        <>
            <FileImporterDialog
                title="Bulk Import Index Actions"
                importsText="Index Actions Imported"
                show={showImportDialog}
                uploadAPIPath={"import_index_actions"}
                importsTableColumns={COLUMNS}
                importsTableOptions={TABLE_OPTIONS}
                transformChanges={(c) => ({
                    ...c,
                    index: indexesById[c.index_id],
                })}
                onCancelled={() => setShowImportDialog(false)}
                onFinished={() => setShowImportDialog(false)}
            />
            <AddRecordDialog
                title="Add Index Action"
                show={showAddDialog}
                fields={ADD_EDIT_FIELDS}
                createAPIPath={"index_action"}
                onCancelled={() => setShowAddDialog(false)}
                onFinished={onFinishedAddingRow}
            />
            <EditRecordDialog
                title="Edit Index Action"
                show={showEditDialog}
                fields={ADD_EDIT_FIELDS}
                record={indexActions[editRowIndex]}
                updateAPIPath={"index_action"}
                onCancelled={() => setShowEditDialog(false)}
                onFinished={onFinishedEditingRow}
            />
            <div className="absolute right-10 top-10">
                <Tooltip title={"Bulk Import IAs"}>
                    <IconButton onClick={() => setShowImportDialog(true)}>
                        <FileUpload />
                    </IconButton>
                </Tooltip>
            </div>
            <QueryDataTable
                ref={queryDataTable}
                tableColumns={COLUMNS}
                tableOptions={TABLE_OPTIONS}
                queryFields={QUERY_FIELDS}
                preprocessResults={enrichResults}
                queryAPIPath="index_actions"
                dynamicLoad={true}
                onResults={setIndexActions}
            />
        </>
    );
}
