import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import Authentication from "../modules/authentication";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoginState, setUserDetails } from "../store/user";
import { setCurrentProcesses } from "../store/indexes";
import { PATH_ROOT } from "../routes";
import {
    getWebsiteStage,
    getWebsiteVersion,
    STAGE_DEV,
    STAGE_PROD,
    STAGE_RC,
} from "../modules/stages";
import { twMerge } from "tailwind-merge";
import { LoadingButton } from "@mui/lab";
import { useCallback, useEffect, useState } from "react";
import { wait } from "../utils/hooks";
import { ReactComponent as IRISLogo } from "../res/images/iris_logo.svg";
import { useInterval } from "../hooks/useInterval";
import { DateTime } from "luxon";
import { DISPLAY_DATE_FORMAT } from "./DataTable";

const gApiClient = Authentication.getAPIClient();

const INDEX_CALC_REFRESH_TIME = 10000;

const PROCESS_NAME_INDEX_CALCULATION = "index_calculation";
const PROCESS_NAME_CPI_CALCULATION = "cpi_calculation";
const PROCESS_NAME_DSS_IMPORT = "securities_import_DSSImporter";
const PROCESS_NAME_FX_IMPORT = "fx_import";
const PROCESS_NAME_TASE_PAYMENTS = "tase_payments";
const PROCESS_NAME_TASE_EOD = "securities_import_TASEEODImporter";
const PROCESS_NAME_TASE_NEXT_OPEN = "securities_import_TASENextOpenImporter";
const PROCESS_NAME_DSS_GLOBAL_BONDS =
    "securities_import_DSSGlobalBondsImporter";

const INDEX_CALCULATION_TYPES = {
    main: "Main Calculation",
    tase_early: "TASE Early Calculation",
    late: "Late",
    recalculation: "Recalculation",
    indices_ca: "Indices CA Report",
};

export default function AppBar() {
    const dispatch = useDispatch();
    const loginState = useSelector((state) => state.user.loginState);
    const userDetails = useSelector((state) => state.user.userDetails);
    const backendDetails = useSelector((state) => state.user.backendDetails);
    const refreshCurrentProcesses = useSelector(
        (state) => state.indexes.refreshCurrentProcesses,
    );
    const currentProcesses = useSelector(
        (state) => state.indexes.currentProcesses,
    );

    const websiteStage = getWebsiteStage();
    const [showResetDBDialog, setShowResetDBDialog] = useState(false);
    const [resettingDB, setResettingDB] = useState(false);

    const navigate = useNavigate();

    const loadLatestIndexCalcProcessState = useCallback(async () => {
        let response = await gApiClient.callApi("process_statuses", {
            method: "GET",
        });

        dispatch(setCurrentProcesses(response.data));
    }, [dispatch]);

    // Refresh the current status of the index calculation process every 10 seconds
    useInterval(loadLatestIndexCalcProcessState, INDEX_CALC_REFRESH_TIME);
    useEffect(() => {
        loadLatestIndexCalcProcessState();
    }, [loadLatestIndexCalcProcessState]);

    useEffect(() => {
        // Some other part of the code wants to immediately refresh the status of the index calculation process
        // e.g. When a user trigger a recalculation
        loadLatestIndexCalcProcessState();
    }, [refreshCurrentProcesses, loadLatestIndexCalcProcessState]);

    const formatProcess = (name) => {
        const process = currentProcesses.find((x) => x.name === name);
        if (!process) {
            return "Loading...";
        }

        if (process.status === "finished") {
            return `Finished (${DateTime.fromMillis(
                process.start_date
                    ? process.start_date
                    : process.import_process_calc_date,
            ).toFormat(DISPLAY_DATE_FORMAT)})`;
        } else if (process.status === "failed") {
            return `Failed (${DateTime.fromMillis(
                process.first_import_timestamp,
            ).toFormat(DISPLAY_DATE_FORMAT)}) - ${
                process.reason ? process.reason.substring(0, 150) : ""
            }`;
        }

        if (name === PROCESS_NAME_INDEX_CALCULATION) {
            return `Running ${
                INDEX_CALCULATION_TYPES[process.calculation_type]
            } (${DateTime.fromMillis(process.start_date).toFormat(
                DISPLAY_DATE_FORMAT,
            )})`;
        }
    };

    const signOut = () => {
        // Sign out and refresh Redux store
        Authentication.logOut();
        dispatch(setLoginState(Authentication.LOGIN_STATUS_NOT_LOGGED_IN));
        dispatch(setUserDetails(null));
        navigate("/login");
    };

    const resetDB = async () => {
        setResettingDB(true);

        // Reset DB using API call
        let response = await gApiClient.callApi("reset-db-from-prod", {
            method: "POST",
        });
        const backgroundFunctionId = response.data.background_function_id;
        let backgroundFunction;

        do {
            response = await gApiClient.callApi("background_function", {
                method: "GET",
                params: {
                    background_function_id: backgroundFunctionId,
                },
            });
            backgroundFunction = response.data;

            await wait(500);
        } while (
            !backgroundFunction ||
            backgroundFunction.status !== "finished"
        );

        // Force a refresh of the website
        window.location.reload();
    };

    return (
        <div
            className={twMerge(
                "text-3xl flex-row flex h-auto overflow-hidden items-center fixed top-0 left-0 right-0",
                websiteStage === STAGE_PROD && "bg-[#7FABEA]",
                websiteStage === STAGE_RC && "bg-orange-500",
                websiteStage === STAGE_DEV && "bg-red-500",
            )}
        >
            <div className="flex-row flex h-full items-center w-96">
                <div className="ml-5 text-white font-bold">
                    <Link to={PATH_ROOT}>
                        <IRISLogo className="w-[100px]" />
                    </Link>
                </div>
                {backendDetails && (
                    <div className="text-base ml-5 flex flex-col text-black">
                        <div>
                            Server: {backendDetails.stage.toUpperCase()} -
                            &nbsp;
                            {backendDetails.version}
                        </div>
                        <div>
                            Website: {getWebsiteStage().toUpperCase()} - &nbsp;
                            {getWebsiteVersion()}
                        </div>
                    </div>
                )}
            </div>
            {loginState !== Authentication.LOGIN_STATUS_NOT_LOGGED_IN &&
                userDetails && (
                    <>
                        <div className="text-white text-lg ml-5 flex flex-col">
                            <div className="mb-0">
                                <b>Index Calculation Process: </b>
                                {formatProcess(PROCESS_NAME_INDEX_CALCULATION)}
                            </div>
                            <div className="mb-0">
                                <b>FX Import: </b>
                                {formatProcess(PROCESS_NAME_FX_IMPORT)}
                            </div>
                            <div className="mb-0">
                                <b>DSS Securities Import: </b>
                                {formatProcess(PROCESS_NAME_DSS_IMPORT)}
                            </div>
                            <div className="mb-0">
                                <b>DSS Global Bonds Import: </b>
                                {formatProcess(PROCESS_NAME_DSS_GLOBAL_BONDS)}
                            </div>
                            <div className="mb-0">
                                <b>CPI Calculation: </b>
                                {formatProcess(PROCESS_NAME_CPI_CALCULATION)}
                            </div>
                            <div className="mb-0">
                                <b>TASE Payments: </b>
                                {formatProcess(PROCESS_NAME_TASE_PAYMENTS)}
                            </div>
                            <div className="mb-0">
                                <b>TASE EOD: </b>
                                {formatProcess(PROCESS_NAME_TASE_EOD)}
                            </div>
                            <div className="mb-0">
                                <b>TASE Next Open: </b>
                                {formatProcess(PROCESS_NAME_TASE_NEXT_OPEN)}
                            </div>
                        </div>
                        <div className="ml-auto text-xl mr-5 text-white">
                            {userDetails.email}
                        </div>
                        {getWebsiteStage() !== STAGE_PROD && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => setShowResetDBDialog(true)}
                                sx={{
                                    mr: 2,
                                    height: 40,
                                    width: 150,
                                }}
                            >
                                Reset DB
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={signOut}
                            sx={{
                                width: 150,
                                height: 40,
                                color: "#ffffff",
                                bgcolor:
                                    websiteStage === STAGE_PROD
                                        ? "#0073ff"
                                        : websiteStage === STAGE_RC
                                        ? "#F3BD7F"
                                        : websiteStage === STAGE_DEV
                                        ? "#EFA9A7"
                                        : null,
                                mr: 2,
                                "&:hover": {
                                    color: "#ffffff",
                                    bgcolor:
                                        websiteStage === STAGE_PROD
                                            ? "#002f67"
                                            : websiteStage === STAGE_RC
                                            ? "#B34A22"
                                            : websiteStage === STAGE_DEV
                                            ? "#AA2E26"
                                            : null,
                                },
                            }}
                        >
                            Sign Out
                        </Button>
                    </>
                )}

            <Dialog
                open={showResetDBDialog}
                onClose={() => {
                    // Prevent from canceling the dialog by clicking on the backdrop
                    return;
                }}
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogTitle>
                    Reset <u>{getWebsiteStage()}</u> DB (from Production)
                </DialogTitle>
                <DialogContent>
                    <div>
                        Are you sure you want to reset DB for this stage (
                        <b>{getWebsiteStage()}</b>) from Production DB? All DB
                        tables in this staging environment will be cleared out,
                        and copied over from Production DB.
                        <br />
                        <br />
                        <b>Note:</b> Website will be reloaded once resetting is
                        done.
                    </div>
                </DialogContent>
                <DialogActions>
                    <LoadingButton onClick={resetDB} loading={resettingDB}>
                        Yes
                    </LoadingButton>
                    <Button
                        onClick={() => setShowResetDBDialog(false)}
                        disabled={resettingDB}
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
