import { useCallback, useState } from "react";
import Dropzone from "react-dropzone";
import ErrorLabel from "./Error";

/**
 * File uploader component (used when uploading spreadsheet files for bulk upload of CAs, etc.)
 * @param onFileChosen a callback function, called when a file is chosen - params for callback:
 *      filename (just filename, not full file path), fileBody (ArrayBuffer)
 * @param maxFiles (optional, default=1) maximum number of files to accept
 * @param displayError (optional, default="") if not empty, will display an error below the field and a red border
 *
 */
export default function FileUploader({
    onFileChosen,
    maxFiles = 1,
    displayError = "",
}) {
    const [fileChosen, setFileChosen] = useState(null);
    const [fileError, setFileError] = useState(false);
    const [isDraggingOver, setIsDraggingOver] = useState(false);

    const onDrop = useCallback((acceptedFiles) => {
        setFileError(false);
        setIsDraggingOver(false);

        acceptedFiles.forEach((file) => {
            const reader = new FileReader();

            setFileChosen(file.name);

            reader.onerror = () => setFileError(true);
            reader.onload = () => {
                const fileContents = reader.result;
                onFileChosen(file.name, fileContents);
            };
            reader.readAsArrayBuffer(file);
        });
    }, []);

    return (
        <Dropzone
            onDragEnter={() => setIsDraggingOver(true)}
            onDragLeave={() => setIsDraggingOver(false)}
            onDrop={onDrop}
            maxFiles={maxFiles}
        >
            {({ getRootProps, getInputProps }) => (
                <>
                    <div
                        className={`min-w-[500px] w-[500px] h-[200px] flex-col flex justify-center border-4 border-blue-400 p-5 ${
                            isDraggingOver ? "border-dotted opacity-50" : ""
                        } ${displayError ? "border-red-500" : ""}`}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        <div className="text-center">
                            Drag &amp; Drop File here, or click here to upload
                            file
                        </div>
                        {fileError && (
                            <ErrorLabel>Couldn't open file</ErrorLabel>
                        )}
                        {fileChosen && (
                            <div className="text-ellipsis w-full overflow-hidden text-center text-sm text-gray-500">
                                {fileChosen}
                            </div>
                        )}
                    </div>
                    {displayError && (
                        <div className="text-red-600 mt-2">{displayError}</div>
                    )}
                </>
            )}
        </Dropzone>
    );
}
