import axios from "axios";
import { saveAs } from "file-saver";

/**
 * Downloads a binary file into a local file
 *
 * @param url file to download
 * @param filename local filename
 * @returns {Promise<void>}
 */
export async function downloadFile(url, filename) {
    const response = await axios.get(url, { responseType: "blob" });
    console.log(`downloadFile got response: ${url}: ${filename}`);

    saveAs(
        new Blob([response.data], { type: "application/octet-stream" }),
        filename,
    );
}
