import * as React from "react";
import { useSelector } from "react-redux";
import {
    customValueRenderer,
    dateFilterColumn,
    freeTextColumn,
    multiSelectColumn,
    nonFilterableColumn,
    thousandsSeparatorRound,
    roundNumbers,
    convertToPercent,
} from "../../components/DataTable";
import QueryDataTable from "../../components/QueryDataTable";
import { DateField, IndexField } from "../../components/Form";
import { DateTime } from "luxon";
import { getSecurityName } from "../../store/securities";
import { getCountryName } from "../../utils/countries";

export default function IndexComponentsParameters() {
    const indexesById = useSelector((state) => state.indexes.indexesById);
    const securitiesById = useSelector(
        (state) => state.securities.securitiesById,
    );
    const dividendTaxCountries = useSelector(
        (state) => state.securities.dividendTaxCountries,
    );

    const QUERY_FIELDS = [
        {
            name: "start_date",
            label: "Start Date",
            type: DateField,
            required: true,
            defaultValue: DateTime.now()
                .startOf("day")
                .minus({ days: 1 })
                .toMillis(),
        },
        {
            name: "end_date",
            label: "End Date",
            type: DateField,
            required: true,
            defaultValue: DateTime.now()
                .startOf("day")
                .minus({ days: 1 })
                .toMillis(),
        },
        {
            name: "index_id",
            label: "Index",
            type: IndexField,
            required: false,
        },
    ];

    const TABLE_COLUMNS = [
        dateFilterColumn("close_date", "Close Date"),
        multiSelectColumn("index_name", "Index Name"),
        multiSelectColumn("index_internal_id", "Internal ID"),
        freeTextColumn(
            "security_id",
            "Security",
            customValueRenderer((x) => getSecurityName(securitiesById[x])),
        ),
        multiSelectColumn("security_currency", "Currency"),
        multiSelectColumn(
            "security_country",
            "Domicile Country",
            customValueRenderer(getCountryName),
        ),
        nonFilterableColumn(
            "security_country_dividend",
            "Dividend Tax",
            convertToPercent(3),
        ),
        nonFilterableColumn("close_price", "Close Price"),
        nonFilterableColumn(
            "close_price_index_currency",
            "Close Price Index Currency",
        ),
        nonFilterableColumn("factor", "Factor", roundNumbers(8)),
        nonFilterableColumn(
            "number_of_shares",
            "Number of Shares",
            thousandsSeparatorRound(0),
        ),
        nonFilterableColumn(
            "total_market_cap",
            "Total Market Cap",
            thousandsSeparatorRound(0),
        ),
        nonFilterableColumn(
            "next_open_close_price",
            "Next Open Close Price",
            thousandsSeparatorRound(0),
        ),
        nonFilterableColumn(
            "next_open_close_price_index_currency",
            "Next Open Close Price Index Currency",
        ),
        nonFilterableColumn(
            "next_open_factor",
            "Next Open Factor",
            roundNumbers(8),
        ),
        nonFilterableColumn(
            "next_open_number_of_shares",
            "Next Open Number of Shares",
            thousandsSeparatorRound(0),
        ),
        nonFilterableColumn(
            "next_open_total_market_cap",
            "Next Open Total Market Cap",
            thousandsSeparatorRound(0),
        ),
        nonFilterableColumn(
            "next_open_weight",
            "Next Open Weight",
            convertToPercent(8),
        ),
        nonFilterableColumn(
            "base_close_price",
            "Base Close Price",
            thousandsSeparatorRound(0),
        ),
        nonFilterableColumn(
            "base_close_price_index_currency",
            "Base Close Price Index Currency",
        ),
        nonFilterableColumn("base_factor", "Base Factor", roundNumbers(8)),
        nonFilterableColumn(
            "base_number_of_shares",
            "Base Number of Shares",
            thousandsSeparatorRound(0),
        ),
        nonFilterableColumn(
            "base_total_market_cap",
            "Base Total Market Cap",
            thousandsSeparatorRound(0),
        ),
        nonFilterableColumn("base_weight", "Base Weight", convertToPercent(8)),
    ];

    const TABLE_OPTIONS = {
        sortOrder: {
            name: "close_date",
            direction: "asc",
        },
    };

    const enrichResults = (results) => {
        return results.map((x) => ({
            ...x,
            index_name: indexesById[x.index_id].name,
            index_internal_id: indexesById[x.index_id].internal_id,
            index_type: indexesById[x.index_id].type,
            security_currency: securitiesById[x.security_id].currency,
            security_country:
                securitiesById[x.security_id].domicile_country_code,
            // Since a single country can have multiple dividend tax records,
            // find the one matching the current row's close_date
            security_country_dividend: dividendTaxCountries.find(
                (c) =>
                    c.country_code ===
                        securitiesById[x.security_id].domicile_country_code &&
                    x.close_date >= c.start_date &&
                    x.close_date <= c.end_date,
            ).dividend_tax,
        }));
    };

    return (
        <>
            <QueryDataTable
                queryFields={QUERY_FIELDS}
                queryAPIPath="close_next_open_prices"
                tableColumns={TABLE_COLUMNS}
                tableOptions={TABLE_OPTIONS}
                preprocessResults={enrichResults}
            />
        </>
    );
}
