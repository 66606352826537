import Login from "./pages/Login";
import NotVerified from "./pages/NotVerified";
import Register from "./pages/Register";
import Main from "./pages/Main";
import ManagedIndexes from "./pages/indexes/ManagedIndexes";
import ManagedExchanges from "./pages/exchanges/ManagedExchanges";
import ManagedSecurities from "./pages/securities/ManagedSecurities";
import IndexCompositions from "./pages/indexes/IndexCompositions";
import CorporateActions from "./pages/securities/CorporateActions";
import IndexActions from "./pages/indexes/IndexActions";
import DividendTaxCountries from "./pages/misc/DividendTaxCountries";
import APIReports from "./pages/index_api/APIReports";
import APIRequests from "./pages/index_api/APIRequests";
import DailyMarketData from "./pages/securities/DailyMarketData";
import IndexesLevels from "./pages/indexes/IndexesLevels";
import IndexComponentsParameters from "./pages/indexes/IndexComponentsParameters";
import ExchangeTradeDates from "./pages/exchanges/ExchangeTradeDates";
import FXRates from "./pages/misc/FXRates";
import CentralBanks from "./pages/misc/CentralBanks";
import ImpliedInflation from "./pages/misc/impliedInflation";

// All URLs of routes in the IRIS website
export const PATH_ROOT = "/";
export const PATH_LOGIN = "/login";
export const PATH_REGISTER = "/register";
export const PATH_NOT_VERIFIED = "/not-verified";
export const PATH_MANAGED_INDEXES = "/managed-indexes";
export const PATH_INDEXES_LEVELS = "/indexes-levels";
export const PATH_INDEX_COMPOSITIONS = "/index-compositions";
export const PATH_MANAGED_EXCHANGES = "/managed-exchanges";
export const PATH_MANAGED_SECURITIES = "/managed-securities";
export const PATH_CORPORATE_ACTIONS = "/corporate-actions";
export const PATH_INDEX_ACTIONS = "/index-actions";
export const PATH_DIVIDEND_TAX_COUNTRIES = "/dividend-tax-countries";
export const PATH_API_REPORTS = "/api-reports";
export const PATH_API_REQUESTS = "/api-requests";
export const PATH_DAILY_MARKET_DATA = "/daily-market-data";
export const PATH_INDEX_COMPONENTS_PARAMETERS = "/index-components-parameters";
export const PATH_EXCHANGE_TRADE_DATES = "/exchange-trade-dates";
export const PATH_FX_RATES = "/fx-rates";
export const PATH_CENTRAL_BANKS = "/central-banks";
export const PATH_IMPLIED_INFLATION = "/implied-inflation";

// All routes in the IRIS website
export const routes = [
    { path: PATH_LOGIN, element: <Login /> },
    { path: PATH_NOT_VERIFIED, element: <NotVerified /> },
    { path: PATH_REGISTER, element: <Register /> },
    { path: PATH_MANAGED_INDEXES, element: <ManagedIndexes /> },
    { path: PATH_INDEXES_LEVELS, element: <IndexesLevels /> },
    { path: PATH_MANAGED_EXCHANGES, element: <ManagedExchanges /> },
    { path: PATH_MANAGED_SECURITIES, element: <ManagedSecurities /> },
    { path: PATH_INDEX_COMPOSITIONS, element: <IndexCompositions /> },
    { path: PATH_INDEX_ACTIONS, element: <IndexActions /> },
    { path: PATH_CORPORATE_ACTIONS, element: <CorporateActions /> },
    { path: PATH_DIVIDEND_TAX_COUNTRIES, element: <DividendTaxCountries /> },
    { path: PATH_CENTRAL_BANKS, element: <CentralBanks /> },
    { path: PATH_API_REPORTS, element: <APIReports /> },
    { path: PATH_API_REQUESTS, element: <APIRequests /> },
    { path: PATH_DAILY_MARKET_DATA, element: <DailyMarketData /> },
    {
        path: PATH_INDEX_COMPONENTS_PARAMETERS,
        element: <IndexComponentsParameters />,
    },
    { path: PATH_EXCHANGE_TRADE_DATES, element: <ExchangeTradeDates /> },
    { path: PATH_FX_RATES, element: <FXRates /> },
    { path: PATH_IMPLIED_INFLATION, element: <ImpliedInflation /> },
    { path: PATH_ROOT, element: <Main /> },
];

// Structure of the side menu links / sub-links
export const sideMenuStructure = [
    {
        title: "Indices Calculation",
        children: [
            { title: "Managed Indices", path: PATH_MANAGED_INDEXES },
            { title: "Indices Compositions", path: PATH_INDEX_COMPOSITIONS },
            { title: "Indices Levels", path: PATH_INDEXES_LEVELS },
            {
                title: "Index Components Parameters",
                path: PATH_INDEX_COMPONENTS_PARAMETERS,
            },
            { title: "Index Actions", path: PATH_INDEX_ACTIONS },
        ],
    },
    {
        title: "Securities",
        children: [
            { title: "Managed Securities", path: PATH_MANAGED_SECURITIES },
            { title: "Corporate Actions", path: PATH_CORPORATE_ACTIONS },
            { title: "Daily Market Data", path: PATH_DAILY_MARKET_DATA },
        ],
    },
    {
        title: "Misc Data",
        children: [
            { title: "FX Rates", path: PATH_FX_RATES },
            { title: "Managed Exchanges", path: PATH_MANAGED_EXCHANGES },
            { title: "Exchange Trade Dates", path: PATH_EXCHANGE_TRADE_DATES },
            {
                title: "Countries Dividend Tax",
                path: PATH_DIVIDEND_TAX_COUNTRIES,
            },
            { title: "Central Banks Interest Rates", path: PATH_CENTRAL_BANKS },
            {
                title: "Implied Inflation and Yield",
                path: PATH_IMPLIED_INFLATION,
            },
        ],
    },
    {
        title: "iNDEX API",
        children: [
            { title: "Reports", path: PATH_API_REPORTS },
            { title: "Requests", path: PATH_API_REQUESTS },
        ],
    },
];
