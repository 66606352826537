import * as React from "react";
import { useSelector } from "react-redux";
import {
    booleanValueRenderer,
    columnWidth,
    customValueRenderer,
    dateFilterColumn,
    dropDownColumn,
    freeTextColumn,
    multiSelectColumn,
    nonFilterableColumn,
    roundNumbers,
    thousandsSeparatorRound,
} from "../../components/DataTable";
import { getSecurityName } from "../../store/securities";
import QueryDataTable from "../../components/QueryDataTable";
import {
    BooleanField,
    MultipleIndexesField,
    MultipleSecuritiesField,
} from "../../components/Form";

const TABLE_OPTIONS = {
    sortOrder: {
        name: "security_id",
        direction: "asc",
    },
};

export default function IndexCompositions() {
    const securitiesById = useSelector(
        (state) => state.securities.securitiesById,
    );
    const indexesById = useSelector((state) => state.indexes.indexesById);

    const COLUMNS = [
        freeTextColumn(
            "security_id",
            "Security",
            customValueRenderer((x) => getSecurityName(securitiesById[x])),
        ),
        freeTextColumn("index.name", "Index"),
        multiSelectColumn("index.internal_id", "Index ID"),
        dateFilterColumn("start_date", "Start Date"),
        dateFilterColumn("end_date", "End Date"),
        nonFilterableColumn("factor", "Factor", roundNumbers(8)),
        nonFilterableColumn("number_of_shares", "Capital", thousandsSeparatorRound(0)),
        dropDownColumn("created_by_analyst", "Created by Analyst", {
            ...booleanValueRenderer(),
            ...columnWidth("10%"),
        }),
        dropDownColumn("created_by_ca", "Created by CA", {
            ...booleanValueRenderer(),
            ...columnWidth("10%"),
        }),
        dropDownColumn("is_active", "Is Active", {
            ...booleanValueRenderer(),
            ...columnWidth("10%"),
        }),
    ];

    const enrichResults = (results) => {
        return results.map((x) => ({
            ...x,
            index: indexesById[x.index_id],
        }));
    };

    const QUERY_FIELDS = [
        {
            name: "indexes",
            label: "Indexes",
            type: MultipleIndexesField,
        },
        {
            name: "securities",
            label: "Securities",
            type: MultipleSecuritiesField,
        },
        {
            name: "is_active",
            label: "Is Active?",
            type: BooleanField,
        },
    ];

    return (
        <>
            <QueryDataTable
                tableColumns={COLUMNS}
                tableOptions={TABLE_OPTIONS}
                queryFields={QUERY_FIELDS}
                preprocessResults={enrichResults}
                queryAPIPath="index_compositions"
                dynamicLoad={true}
            />
        </>
    );
}
