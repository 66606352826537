import { configureStore } from "@reduxjs/toolkit";
import indexesReducer from "./indexes";
import userReducer from "./user";
import exchangesReducer from "./exchanges";
import securitiesReducer from "./securities";
import reportsReducer from "./reports";

// Main Redux store for the IRIS website
export const store = configureStore({
    reducer: {
        indexes: indexesReducer,
        exchanges: exchangesReducer,
        securities: securitiesReducer,
        user: userReducer,
        reports: reportsReducer,
    },
});
